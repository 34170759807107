import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import {useForm} from "react-hook-form";
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import ModalSave from '../../modal-save'
import '../create-cabinet-section.sass'

const UpdateFood = ({customerId, genobiosisService})=>{
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [dataVal, setDataVal] = useState('')
    const [vitamin, setVitamin] = useState('')
    const [caffeinePerception, setCaffeinePerception] = useState('')
    const [lactoseIntolerance, setLactoseIntolerance] = useState('')
    const [glutenIntolerance, setGlutenIntolerance] = useState('')
    const [snacksNeeds, setSnacksNeeds] = useState('')
    const [fastingDays, setFastingDays] = useState('')
    const result =(res,id,num)=>{
        return(
            <div key={id}>
                <span>
                    <label htmlFor={'gene'+num+'food'+id}>Ген</label>
                    <input type='text' name={'gene'+num+'food'+id} placeholder="Ген" ref={register} defaultValue={res.gene}/>
                    {/*{`${errors}.gene${idx}${index}` && <p className="error-text">{'*required'}</p>}*/}
                    </span>
                <span>
                    <label htmlFor={'polymorphism'+num+'food'+id}>Полиморфизм</label>
                    <input type='text' name={'polymorphism'+num+'food'+id} placeholder="Полиморфизм"
                           ref={register} defaultValue={res.polymorphism}/>
                </span>
                <span>
                    <label htmlFor={'type'+num+'food'+id}>Ваш тип</label>
                    <input type='text' name={'type'+num+'food'+id} placeholder="Ваш тип"
                           ref={register} defaultValue={res.type}/>
                </span>
            </div>
        )
    }
    const testFunc = ()=>{
         genobiosisService.foodGet(customerId)
            .then((data)=>{

                const mainData = data.data.data
                console.log(mainData)
                setVitamin(
                    mainData.vitamin.map((val,idx)=>{
                        return(
                            <div key={idx}>
                                <div><b>{val.name}</b></div>
                                <span>
                                  <label htmlFor={`name${idx}`}>Название</label>
                                  <input type='text' name={`name${idx}`} placeholder="Название" ref={register} defaultValue={val.name}/>
                                    {/*{`${errors}.${data.name}` &&  <p className="error-text">{'*required'}</p>}*/}
                                </span>
                                <span>
                                  <label htmlFor={`chance${idx}`}>Риск</label>
                                  <input type='text' name={`chance${idx}`} placeholder="Риск" ref={register} defaultValue={val.chance}/>
                                    {/*{`${errors}.${data.chance}` &&  <p className="error-text">{'*required'}</p>}*/}
                                </span>
                                <span>
                                  <label htmlFor={`percent${idx}`}>%</label>
                                  <input type='number'  step="any" name={`percent${idx}`} placeholder="%" ref={register} defaultValue={val.percent} />
                                    {/*{`${errors}.${data.percent}` &&  <p className="error-text">{'*required'}</p>}*/}
                                </span>
                                <span>
                                  <label htmlFor={`recommendations${idx}`}>Расшифровка и рекомендации генетика</label>
                                  <input type='text' name={`recommendations${idx}`} placeholder="Расшифровка и рекомендации генетика" ref={register} defaultValue={val.recommendations}/>
                                    {/*{`${errors}.${data.recommendations}` &&  <p className="error-text">{'*required'}</p>}*/}
                                </span>
                                {val.results.map((res, id)=>{
                                    return(
                                        <div key={id}>
                                            <span>
                                                <label htmlFor={'gene'+idx+id}>Ген</label>
                                                <input type='text' name={'gene'+idx+id} placeholder="Ген" ref={register} defaultValue={res.gene}/>
                                                {/*{`${errors}.gene${idx}${index}` && <p className="error-text">{'*required'}</p>}*/}
                                            </span>
                                            <span>
                                                <label htmlFor={'polymorphism'+idx+id}>Полиморфизм</label>
                                                <input type='text' name={'polymorphism'+idx+id} placeholder="Полиморфизм"
                                                       ref={register} defaultValue={res.polymorphism}/>
                                            </span>
                                            <span>
                                                <label htmlFor={'type'+idx+id}>Ваш тип</label>
                                                <input type='text' name={'type'+idx+id} placeholder="Ваш тип"
                                                       ref={register} defaultValue={res.type}/>
                                            </span>
                                            {/*<span>*/}
                                                {/*<button type="button" onClick={()=>{*/}
                                                {/*}}>X</button>*/}
                                            {/*</span>*/}
                                            {/*<span>*/}
                                                {/*<button type="button" onClick={() => {*/}
                                                    {/*const res = mainData.vitamin[idx].results*/}
                                                    {/*if(!id){*/}
                                                            {/*res.push(*/}
                                                                {/*{*/}
                                                                    {/*gene: `gene${idx}${mainData.vitamin[idx].results.length}`,*/}
                                                                    {/*polymorphism: `polymorphism${idx}${mainData.vitamin[idx].results.length}`,*/}
                                                                    {/*type: `type${idx}${mainData.vitamin[idx].results.length}`*/}
                                                                {/*}*/}
                                                            {/*)*/}

                                                    {/*}*/}
                                                    {/*console.log(res)*/}
                                                {/*}}>+</button>*/}
                                            {/*</span>*/}
                                        </div>
                                    )
                                }
                                )}
                            </div>
                        )
                    })
                )
                setCaffeinePerception(
                    mainData.caffeinePerception.results.map((res, id)=> result(res, id, 0))
                )
                setLactoseIntolerance(
                    mainData.lactoseIntolerance.results.map((res, id)=> result(res, id, 1))
                )
                setGlutenIntolerance(
                    mainData.glutenIntolerance.results.map((res, id)=> result(res, id, 2))
                )
                setSnacksNeeds(
                    mainData.snacksNeeds.results.map((res, id)=> result(res, id, 3))
                )
                setFastingDays(
                    mainData.fastingDays.results.map((res, id)=> result(res, id, 4))
                )
                setDataVal({
                    daily_calorie_determination: mainData.daily_calorie_determination,
                    diet_type: mainData.diet_type,
                    body_mass: mainData.body_mass,
                    proteins: mainData.composition.proteins,
                    fats: mainData.composition.fats,
                    carbohydrates: mainData.composition.carbohydrates,
                    recommendations: mainData.recommendations,
                    forbidden_products: mainData.forbidden_products,
                    portion: mainData.follow.portion,
                    water: mainData.follow.water,
                    cooking: mainData.follow.cooking,
                    follow_fats: mainData.follow.fats,
                    flour_products: mainData.follow.flour_products,
                    protein_products: mainData.follow.protein_products,
                    animal_fats: mainData.restriction.animal_fats,
                    fast_carbohydrates: mainData.restriction.fast_carbohydrates,
                    sausages: mainData.restriction.sausages,
                    vegetables: mainData.foodPlate.vegetables,
                    healthy_proteins: mainData.foodPlate.healthy_proteins,
                    fruit: mainData.foodPlate.fruit,
                    whole_grains: mainData.foodPlate.whole_grains,
                    sensitivity: mainData.caffeinePerception.sensitivity,
                    recommendationsCaffeinePerception: mainData.caffeinePerception.recommendations,
                    percentCaffeinePerception: mainData.caffeinePerception.percent,
                    chanceLactoseIntolerance: mainData.lactoseIntolerance.chance,
                    recommendationsLactoseIntolerance: mainData.lactoseIntolerance.recommendations,
                    percentLactoseIntolerance: mainData.lactoseIntolerance.percent,
                    chanceGlutenIntolerance: mainData.glutenIntolerance.chance,
                    recommendationsGlutenIntolerance: mainData.glutenIntolerance.recommendations,
                    percentGlutenIntolerance: mainData.glutenIntolerance.percent,
                    chanceSnacksNeeds: mainData.snacksNeeds.chance,
                    recommendationsSnacksNeeds: mainData.snacksNeeds.recommendations,
                    percentSnacksNeeds: mainData.snacksNeeds.percent,
                    needsFastingDays: mainData.fastingDays.need,
                    recommendationsFastingDays: mainData.fastingDays.recommendations,
                    percentFastingDays: mainData.fastingDays.percent,

                })
                setOpen(true)
            })
            .catch((err)=>{console.log(err)})

    }
    const { register, handleSubmit } = useForm({
        defaultValues: dataVal
    });

    const onSubmit = (value, event) => {
        event.preventDefault()
        const fields = (num,idx)=>{
            return{
                gene: value[`gene${num}${idx}`],
                polymorphism: value[`polymorphism${num}${idx}`],
                type: value[`type${num}${idx}`]
            }
        }


        let JSONData = {
            user_id: customerId+'',
            daily_calorie_determination: value.daily_calorie_determination,
            diet_type: value.diet_type,
            body_mass: value.body_mass,
            composition:{
                proteins: value.proteins,
                fats: value.fats,
                carbohydrates: value.carbohydrates
            },
            recommendations: value.recommendations,
            forbidden_products: value.forbidden_products,
            follow:{
                portion: value.portion,
                water: value.water,
                cooking: value.cooking,
                fats: value.follow_fats,
                flour_products: value.flour_products,
                protein_products: value.protein_products

            },
            restriction:{
                animal_fats: value.animal_fats,
                fast_carbohydrates: value.fast_carbohydrates,
                sausages: value.sausages
            },
            foodPlate:{
                vegetables: value.vegetables,
                healthy_proteins: value.healthy_proteins,
                fruit: value.fruit,
                whole_grains: value.whole_grains
            },
            vitamin:[
                {
                    name: value.name0,
                    chance: value.chance0,
                    percent:value.percent0,
                    recommendations:value.recommendations0,
                    results:[
                        {
                            gene: value.gene00,
                            polymorphism: value.polymorphism00 ,
                            type: value.type00
                        }
                    ]
                },
                {

                    name: value.name1,
                    chance: value.chance1,
                    percent: value.percent1,
                    recommendations: value.recommendations1,
                    results:[
                        {
                            gene: value.gene10,
                            polymorphism: value.polymorphism10,
                            type: value.type10
                        }
                    ]
                },
                {
                    name: value.name2,
                    chance: value.chance2,
                    percent:value.percent2,
                    recommendations: value.recommendations2,
                    results:[
                        {
                            gene: value.gene20,
                            polymorphism: value.polymorphism20,
                            type: value.type20
                        }
                    ]
                },
                {
                    name: value.name3,
                    chance: value.chance3,
                    percent: value.percent3,
                    recommendations: value.recommendations3,
                    results:[
                        {
                            gene: value.gene30,
                            polymorphism: value.polymorphism30,
                            type: value.type30
                        }
                    ]
                },
                {
                    name: value.name4,
                    chance: value.chance4,
                    percent: value.percent4,
                    recommendations: value.recommendations4,
                    results:[
                        {
                            gene: value.gene40,
                            polymorphism: value.polymorphism40,
                            type: value.type40
                        }
                    ]
                },
                {
                    name: value.name5,
                    chance: value.chance5,
                    percent: value.percent5,
                    recommendations: value.recommendations5,
                    results:[
                        {
                            gene: value.gene50,
                            polymorphism: value.polymorphism50,
                            type: value.type50
                        }
                    ]
                },
                {
                    name: value.name6,
                    chance: value.chance6,
                    percent: value.percent6,
                    recommendations: value.recommendations6,
                    results:[
                        {
                            gene: value.gene60,
                            polymorphism: value.polymorphism60,
                            type: value.type60
                        }
                    ]
                },
                {
                    name: value.name7,
                    chance: value.chance7,
                    percent: value.percent7,
                    recommendations: value.recommendations7,
                    results:[
                        {
                            gene: value.gene70,
                            polymorphism: value.polymorphism70,
                            type: value.type70
                        }
                    ]
                },
                {
                    name: value.name8,
                    chance: value.chance8,
                    percent: value.percent8,
                    recommendations: value.recommendations8,
                    results:[
                        {
                            gene: value.gene80,
                            polymorphism: value.polymorphism80,
                            type: value.type80
                        }
                    ]
                },
            ],
            caffeinePerception: {
                sensitivity:value.sensitivity,
                recommendations:value.recommendationsCaffeinePerception,
                percent:value.percentCaffeinePerception,
                results:[
                    {
                        gene: value.gene0food0,
                        polymorphism: value.polymorphism0food0 ,
                        type: value.type0food0
                    }
                ]
            },
            lactoseIntolerance: {
                chance:value.chanceLactoseIntolerance,
                recommendations:value.recommendationsLactoseIntolerance,
                percent:value.percentLactoseIntolerance,
                results:[
                    {
                        gene: value.gene1food0,
                        polymorphism: value.polymorphism1food0 ,
                        type: value.type1food0
                    }
                ]
            },
            glutenIntolerance: {
                chance:value.chanceGlutenIntolerance,
                recommendations:value.recommendationsGlutenIntolerance,
                percent:value.percentGlutenIntolerance,
                results:[
                    {
                        gene: value.gene2food0,
                        polymorphism: value.polymorphism2food0 ,
                        type: value.type2food0
                    }
                ]
            },
            snacksNeeds: {
                chance:value.chanceSnacksNeeds,
                recommendations:value.recommendationsSnacksNeeds,
                percent:value.percentSnacksNeeds,
                results:[
                    {
                        gene: value.gene3food0,
                        polymorphism: value.polymorphism3food0 ,
                        type: value.type3food0
                    }
                ]
            },
            fastingDays: {
                need: value.needsFastingDays,
                recommendations:value.recommendationsFastingDays,
                percent:value.percentFastingDays,
                results:[
                    {
                        gene: value.gene4food0,
                        polymorphism: value.polymorphism4food0 ,
                        type: value.type4food0
                    }
                ]
            },

        }
        for(let i = 0; i<=9; i++){
            for(let j = 1; j<20; j++){
                if(value[`gene${i}${j}`]){
                    JSONData.vitamin[i].results.push(fields(i,j))
                }

            }

        }
        const nextVal = (num,idx)=>{
            return{
                gene: value[`gene${num}food${idx}`],
                polymorphism: value[`polymorphism${num}food${idx}`],
                type: value[`type${num}food${idx}`]
            }
        }

        for(let j = 1; j<20; j++) {
            if (value[`gene${0}food${j}`]) {
                JSONData.caffeinePerception.results.push(nextVal(0, j))
            }
        }
        for(let j = 1; j<20; j++) {
            if (value[`gene${2}food${j}`]) {
                JSONData.lactoseIntolerance.results.push(nextVal(1, j))
            }
        }
        for(let j = 1; j<20; j++) {
            if (value[`gene${1}food${j}`]) {
                JSONData.glutenIntolerance.results.push(nextVal(2, j))
            }
        }

        for(let j = 1; j<20; j++) {
            if (value[`gene${3}food${j}`]) {
                JSONData.snacksNeeds.results.push(nextVal(3, j))
            }
        }
        for(let j = 1; j<20; j++){
            if(value[`gene${4}food${j}`]){
                JSONData.fastingDays.results.push(nextVal(4,j))
            }
        }

        let data = JSON.stringify(JSONData)

        genobiosisService.foodUpdate(customerId, {data})
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))


    }
    // const testFunc = ()=>{
    //     setOpen(true)
    // }
    const closeConfirm = ()=>{
        setOpenModal(false)
        setOpen(false)
    }
    return(
        <div>
            <button onClick={testFunc}>Update Food</button>
            <ModalSave open={openModal} onClose={closeConfirm} />
            <Modal show={open} onHide={()=>{setOpen(false)}} dialogClassName="modal-90w">
                <Modal.Header closeButton/>
                <div className="create-food-wrapped">
                    <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                        <div className='section-wrapped'>
                            <h4>Общие данные</h4>
                            <div>
                                <label htmlFor="daily_calorie_determination">Ккалорий в день</label>
                                <input name="daily_calorie_determination" placeholder="Ккалорий в день" ref={register} />
                            </div>
                            <div>
                                <label htmlFor="diet_type">Тип диеты</label>
                                <input name="diet_type" placeholder="Тип диеты" ref={register} />
                            </div>
                            <div>
                                <label htmlFor="body_mass">Индекс массы тела</label>
                                <input type='number'  step="any" name="body_mass" placeholder="Индекс массы тела" ref={register} />
                            </div>
                            <div>
                                <div><b>Состав и порции</b></div>
                                <span>
                                    <label htmlFor="proteins">Белки %</label>
                                    <input type='number'  step="any" name="proteins" placeholder="Белки" ref={register} />
                                </span>
                                <span>
                                    <label htmlFor="fats">Жиры %</label>
                                    <input type='number'  step="any" name="fats" placeholder="Жиры" ref={register} />
                                </span>
                                <span>
                                    <label htmlFor="carbohydrates">Углеводы %</label>
                                    <input type='number'  step="any"name="carbohydrates" placeholder="Углеводы" ref={register} />
                                </span>
                            </div>
                            <div>
                                <label htmlFor="recommendations">Рекомендации</label>
                                <input type='text' name="recommendations" placeholder="Рекомендации" ref={register} />
                            </div>
                            <div>
                                <label htmlFor="forbidden_products">Запретные продукты</label>
                                <input type='text' name="forbidden_products" placeholder="Запретные продукты" ref={register} />
                            </div>
                            <div>
                                <div><b>Придерживаться</b></div>
                                <span>
                                    <label htmlFor="portion">Порции</label>
                                    <input type='text' name="portion" placeholder="Порции" ref={register} />
                                </span>
                                <span>
                                    <label htmlFor="water">Вода</label>
                                    <input type='text' name="water" placeholder="Вода" ref={register} />
                                </span>
                                <span>
                                    <label htmlFor="cooking">Приготовление</label>
                                    <input type='text' name="cooking" placeholder="Приготовление" ref={register} />
                                </span>
                                <span>
                                    <label htmlFor="follow_fats">Жиры</label>
                                    <input type='text' name="follow_fats" placeholder="Жиры" ref={register} />
                                </span>
                                <span>
                                    <label htmlFor="flour_products">Продукты из муки</label>
                                    <input type='text' name="flour_products" placeholder="Продукты из муки" ref={register} />
                                </span>
                                <span>
                                    <label htmlFor="protein_products">Белковые продукты</label>
                                    <input type='text' name="protein_products" placeholder="Белковые продукты" ref={register} />
                                </span>
                            </div>
                            <div>
                                <div><b>Ограничить</b></div>
                                <span>
                                    <label htmlFor="animal_fats">Животные жиры</label>
                                    <input type='text' name="animal_fats" placeholder="Животные жиры" ref={register} />
                                </span>
                                <span>
                                    <label htmlFor="fast_carbohydrates">"Быстрые углеводы"</label>
                                    <input type='text' name="fast_carbohydrates" placeholder="Быстрые углеводы" ref={register} />
                                </span>
                                <span>
                                    <label htmlFor="sausages">Колбасы любых видов</label>
                                    <input type='text' name="sausages" placeholder="Колбасы любых видов" ref={register} />
                                </span>
                            </div>
                            <div>
                                <div><b>Тарелка здорового питания</b></div>
                                <span>
                                    <label htmlFor="vegetables">Овощи %</label>
                                    <input type='number'  step="any" name="vegetables" placeholder="Овощи" ref={register} />
                                </span>
                                <span>
                                    <label htmlFor="healthy_proteins">Полезные белки %</label>
                                    <input type='number'  step="any" name="healthy_proteins" placeholder="Полезные белки" ref={register} />

                                </span>
                                <span>
                                    <label htmlFor="whole_grains">Цельнозерновые %</label>
                                    <input type='number'  step="any" name="whole_grains" placeholder="Цельнозерновые" ref={register} />
                                </span>
                                <span>
                                    <label htmlFor="fruit">Фрукты %</label>
                                    <input type='number'  step="any" name="fruit" placeholder="Фрукты" ref={register} />
                                </span>
                            </div>
                        </div>
                        <div className='section-wrapped'>
                            <h4>Витамины и минералы</h4>
                            {vitamin}
                        </div>
                        <div className='section-wrapped'>
                            <h4>Пищевые непереносимости и Пищевые привычки</h4>
                            <div>
                                <div><b>Восприятие кофеина</b></div>
                                <span>
                                  <label htmlFor="sensitivity">Риск</label>
                                  <input type='text' name="sensitivity" placeholder="Риск" ref={register}  />
                                </span>
                                <span>
                                  <label htmlFor="recommendationsCaffeinePerception">Расшифровка и рекомендации генетика</label>
                                  <input type='text' name="recommendationsCaffeinePerception" placeholder="Расшифровка и рекомендации генетика" ref={register} />
                                </span>
                                <span>
                                  <label htmlFor="percentCaffeinePerception">%</label>
                                  <input type='number'  step="any" name="percentCaffeinePerception" placeholder="%" ref={register} />
                                </span>
                                {caffeinePerception}
                            </div>
                            <div>
                                <div><b>Непереносимость лактозы</b></div>
                                <span>
                                  <label htmlFor="chanceLactoseIntolerance">Риск</label>
                                  <input type='text' name="chanceLactoseIntolerance" placeholder="Риск" ref={register}  />
                                </span>
                                <span>
                                  <label htmlFor="recommendationsLactoseIntolerance">Расшифровка и рекомендации генетика</label>
                                  <input type='text' name="recommendationsLactoseIntolerance" placeholder="Расшифровка и рекомендации генетика" ref={register} />
                                </span>
                                <span>
                                  <label htmlFor="percentLactoseIntolerance">%</label>
                                  <input type='number'  step="any" name="percentLactoseIntolerance" placeholder="%" ref={register} />
                                </span>
                                {lactoseIntolerance}
                            </div>
                            <div>
                                <div><b>Непереносимость глютена</b></div>
                                <span>
                                  <label htmlFor="chanceGlutenIntolerance">Риск</label>
                                  <input type='text' name="chanceGlutenIntolerance" placeholder="Риск" ref={register}  />
                                </span>
                                <span>
                                  <label htmlFor="recommendationsGlutenIntolerance">Расшифровка и рекомендации генетика</label>
                                  <input type='text' name="recommendationsGlutenIntolerance" placeholder="Расшифровка и рекомендации генетика" ref={register} />
                                </span>
                                <span>
                                  <label htmlFor="percentGlutenIntolerance">%</label>
                                  <input type='number'   step="any" name="percentGlutenIntolerance" placeholder="%" ref={register} />
                                </span>
                                {glutenIntolerance}
                            </div>
                            <div>
                                <div><b>Частые перекусы</b></div>
                                <span>
                                  <label htmlFor="chanceSnacksNeeds">Риск</label>
                                  <input type='text' name="chanceSnacksNeeds" placeholder="Риск" ref={register}  />
                                </span>
                                <span>
                                  <label htmlFor="recommendationsSnacksNeeds">Расшифровка и рекомендации генетика</label>
                                  <input type='text' name="recommendationsSnacksNeeds" placeholder="Расшифровка и рекомендации генетика" ref={register} />
                                </span>
                                <span>
                                  <label htmlFor="percentSnacksNeeds">%</label>
                                  <input type='number'  step="any" name="percentSnacksNeeds" placeholder="%" ref={register} />
                                </span>
                                {snacksNeeds}
                            </div>
                            <div>
                                <div><b>Потребность в разгрузочных днях</b></div>
                                <span>
                                  <label htmlFor="needsFastingDays">Потребность</label>
                                  <input type='text' name="needsFastingDays" placeholder="Риск" ref={register}  />
                                </span>
                                <span>
                                  <label htmlFor="recommendationsFastingDays">Расшифровка и рекомендации генетика</label>
                                  <input type='text' name="recommendationsFastingDays" placeholder="Расшифровка и рекомендации генетика" ref={register} />
                                </span>
                                <span>
                                  <label htmlFor="percentFastingDays">%</label>
                                  <input type='number'  step="any" name="percentFastingDays" placeholder="%" ref={register} />
                                </span>
                                {fastingDays}
                            </div>
                        </div>
                        <button  type="submit">Изменить</button>
                    </form>
                </div>
            </Modal>
        </div>
    )
}
export default withGenobiosisService()(UpdateFood)
