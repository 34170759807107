import React, {useState, useEffect}  from 'react'
import withGenobiosisService from "../hoc/with-genobiosis-service";
import compose from "../../utils/compose";
import {useForm} from "react-hook-form";
import './admin-page.sass'
import ModalSave from './modal-save'

const CreateCustomer = ({genobiosisService}) =>{
    const [openModal, setOpenModal] = useState(false)
    const { register, handleSubmit, errors } = useForm()
    const onSubmit = (value, event) => {
        event.preventDefault()
        genobiosisService.consumersCreate({
            surname: value.surname,
            given_name: value.given_name,
            patronymic: value.patronymic,
            sex: value.sex,
            phone: value.phone,
            email: value.email,
            birthday: value.birthday,
            deliver_address: value.deliver_address,
            register_address: value.register_address,
            passport_data: value.passport_data,
            password: value.password,
            passport_type: value.passport_type,
            lang: value.lang
        })
            .then(() => {
                setOpenModal(true)
                event.target.reset()

            })
            .catch(err => console.log(err))

    };

    return(
        <section className="menu-form-cr">
            <ModalSave open={openModal} onClose={()=>setOpenModal(false)} />
            <div className="d-flex justify-content-between header-btn">
                <h1>Создать нового клиента</h1>
            </div>
            <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label htmlFor="surname">Фамилия</label>
                    <input  type='text' name="surname" placeholder='Фамилия' ref={register({ required: true })} />
                </div>
                {errors.surname &&  <p className="error-text">{'*required'}</p>}
                <div>
                    <label htmlFor="given_name">Имя</label>
                    <input  type='text' name="given_name" placeholder="Имя" ref={register({ required: true })} />
                </div>
                {errors.given_name && <p className="error-text">{'*required'}</p>}
                <div>
                    <label htmlFor="patronymic">Отчество</label>
                    <input  type='text' name="patronymic" placeholder="Отчество" ref={register({ required: true })} />
                </div>
                {errors.patronymic && <p className="error-text">{'*required'}</p>}
                <div>
                    <label htmlFor="sex">Пол</label>
                    <select ref={register({ required: true })} name="sex">
                        <option>man</option>
                        <option>woman</option>
                    </select>
                </div>
                {errors.sex && <p className="error-text">{'*required'}</p>}
                <div>
                    <label htmlFor="passport_type">Тип паспорта</label>
                    <select ref={register({ required: true })} name="passport_type">
                        <option>Gen</option>
                        <option>Gen++</option>
                        <option>Genom</option>
                    </select>
                </div>
                {errors.passport_type && <p className="error-text">{'*required'}</p>}
                <div>
                    <label htmlFor="phone">Телефон</label>
                    <input type='phone' name="phone" placeholder="+ХХХХХХХХХХХХ" ref={register({ required: true })} />
                </div>
                {errors.phone && <p className="error-text">{'*required'}</p>}
                <div>
                    <label htmlFor="birthday">Дата рождения</label>
                    <input type='text' name="birthday" placeholder="YYYY-MM-DD" ref={register({ required: true })} />
                </div>
                {errors.birthday && <p className="error-text">{'*required'}</p>}
                <div>
                    <label htmlFor="deliver_address">Адрес доставки паспорта</label>
                    <input type='text' name="deliver_address" placeholder="Адрес доставки паспорта" ref={register({ required: true })} />
                </div>
                {errors.deliver_address && <p className="error-text">{'*required'}</p>}
                <div>
                    <label htmlFor="register_address">Адрес регистрации</label>
                    <input type='text' name="register_address" placeholder="Адрес регистрации" ref={register({ required: true })} />
                </div>
                {errors.register_address && <p className="error-text">{'*required'}</p>}
                <div>
                    <label htmlFor="passport_data">Паспортные данные</label>
                    <input type='text' name="passport_data" placeholder="Паспортные данные" ref={register({ required: true })} />
                </div>
                {errors.passport_data && <p className="error-text">{'*required'}</p>}
                <div>
                    <label htmlFor="email">Email</label>
                    <input type='email' name="email" placeholder="email" ref={register({ required: true })} />
                </div>
                {errors.email && <p className="error-text">{'*required'}</p>}
                <div>
                    <label htmlFor="password">Пароль</label>
                    <input type='password' name="password" placeholder="password" ref={register({ required: true })} />
                </div>
                {errors.password && <p className="error-text">{'*required'}</p>}
                <div>
                    <label htmlFor="lang">Язик</label>
                    <select ref={register({ required: true })} name="lang">
                        <option>ro</option>
                        <option>ru</option>
                        <option>en</option>
                    </select>
                </div>
                {errors.lang && <p className="error-text">{'*required'}</p>}
                <button type="submit" >Создать</button>
            </form>
        </section>
    )
}

export default compose(withGenobiosisService())(CreateCustomer)