import React,{ useState } from 'react'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import { useForm } from "react-hook-form";
import '../create-cabinet-section.sass'
import ModalSave from '../../modal-save'

const CreateInheritedIllness = ({ genobiosisService })=>{
    const [openModal, setOpenModal] = useState(false)
    const { register, handleSubmit, errors } = useForm()
    const dataIllness = [
        {title:'Синдром Андерманна (агенезия мозолистого тела с периферической невропатией)'},
        {title:'Дефицит альфа-1-антитрипсина'},
        {title:'Аутосомно-рецессивная спастическая атаксия Шарлевуа - Сагенэ (MIM 270550'},
        {title:'Аутосомно-рецессивная поликистозная болезнь почек'},
        {title:'Бета-талассемия'},
        {title:'Синдром Блума'},
        {title:'Болезнь Канаван - ван Богерта (спонгиозная младенческая дегенерация)'},
        {title:'Врожденные нарушения гликозилирования cdg синдром тип iа (синдром Жакена)'},
        {title:'Аутосомно-рецессивная несиндромальная нейросенсорная тугоухость, связанная с мутациями в гене коннексина 26 (GJB2)'},
        {title:'Муковисцидоз'},
        {title:'Синдром недостаточности бифункционального белка пероксисом'},
        {title:'Синдром дефицита дигидролипоил дегидрогеназы'},
        {title:'Дефицит дигидропиримидиндегидрогеназы'},
        {title:'Дефицит фактора XI'},
        {title:'Семейная дисавтономия (синдром Райли - Дея)'},
        {title:'Семейная гиперхолестеринемия типа Iib'},
        {title:'Семейный гиперинсулинизм (связанный с ABCC8)'},
        {title:'Семейная средиземноморская лихорадка'},
        {title:'Анемия Фанкони (связанная с FANCC)'},
        {title:'Дефицит глюкозо-6- фосфатдегидрогеназы'},
        {title:'Болезнь Гоше (сфинголипидоз)'},
        {title:'Болезнь накопления гликогена типа 1а'},
        {title:'Болезнь накопления гликогена типа 1b'},
        {title:'GRACILE – синдром (финский летальный неонатальный метаболический синдром; лактатацидоз с печеночным гемосидерозом)'},
        {title:'Гемохроматоз (связанный с HFE)'},
        {title:'Фруктоземия'},
        {title:'Гипертрофическая кардиомиопатия (делеция 25bp в гене MYBPC3)'},
        {title:'Пограничный буллезный эпидермолиз (связанный с LAMB3)'},
        {title:'Прогрессирующая мышечная дистрофия Эрба-Рота'},
        {title:'Лейциноз (болезнь кленового сиропа) тип 1B'},
        {title:'Дефицит ацил-КоА дегидрогеназы жирных кислот со средней длиной цепи (MCAD)'},
        {title:'Сиалолипидоз (муколипидоз IV)'},
        {title:'Нейрональный цероидный липофусциноз (связанный с CLN5)'},
        {title:'Болезнь Ниманна-Пика, тип А'},
        {title:'Синдром хромосомных поломок Неймеген'},
        {title:'Синдром Пендреда'},
        {title:'Фенилкетонурия'},
        {title:'Первичная гипероксалурия II типа (PH2)'},
        {title:'Ризомелическая точечная хондродисплазия типа 1 (RCDP1)'},
        {title:'Болезнь Салла'},
        {title:'Серповидноклеточная анемия'},
        {title:'Синдром Шегрена - Ларссона'},
        {title:'Болезнь Тея - Сакса'},
        {title:'Торсионная дистония'},
        {title:'Транстиретиновый амилоидоз сердца'},
        {title:'Транстиретиновая семейная амилоидная полинейропатия'},
        {title:'Тирозинемия типа I'},
        {title:'Синдром Ушера III типа'},
        {title:'Синдром Ушера I типа'},
        {title:'Расстройства спектра Зеллвегера'}
    ]


    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: value.user_id,
            inheritedIllnessItems:[]
        }

        const fields = (num,idx)=>{
            return{
                mutation: value[`${num}mutation${idx}`],
                genotype: value[`${num}genotype${idx}`],
                risk_allele: value[`${num}risk_allele${idx}`],
                normal_allele: value[`${num}normal_allele${idx}`]

            }
        }
        const fieldsName = (idx)=> {
            return{
                name: dataIllness[idx].title,
                inheritedIllnessItemInfo: []
            }
        }
        for(let i = 0; i<dataIllness.length; i++){
            JSONData.inheritedIllnessItems.push(fieldsName(i))
            for(let j = 0; j<40; j++){
                if(value[`${i}mutation${j}`]){
                    JSONData.inheritedIllnessItems[i].inheritedIllnessItemInfo.push(fields(i,j))
                }

            }

        }
        let data = JSON.stringify(JSONData)
        // console.log(JSONData)
        genobiosisService.inheritedIllnessCreate({
            data
        })
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }
    const Result = (index) =>{
        const [fields, setFields] = useState([{ value: null }]);
        function handleAdd() {
            const values = [...fields];
            values.push({ value: null });
            setFields(values);
        }
        function handleRemove(i) {
            const values = [...fields];
            values.splice(i, 1);
            setFields(values);
        }
        return(
            <React.Fragment>
                <span>
                    <button type="button" onClick={() => handleAdd()}>+</button>
                </span>
                {fields.map((field, idx) => {
                    return (
                        <div key={`${field}-${idx}`}>
                            <span>
                                <label htmlFor={index+'mutation'+idx}>Мутация</label>
                                <input type='text' name={index+'mutation'+idx} placeholder="Мутация "
                                       ref={register({required: true})}
                                       defaultValue={'ISi410'+index+idx}
                                />
                                {errors[index+'mutation'+idx] && <p className="error-text">{'*required'}</p>}
                            </span>
                            <span>
                                <label htmlFor={index+'genotype'+idx}>Ваш генотип</label>
                                <input type='text' name={index+'genotype'+idx} placeholder="Ваш генотип"
                                       ref={register({required: true})}
                                       defaultValue={'A'+index+idx}
                                />
                                {errors[index+'genotype'+idx] && <p className="error-text">{'*required'}</p>}
                            </span>
                            <span>
                                <label htmlFor={index+'risk_allele'+idx}>Аллель риска</label>
                                <input type='text' name={index+'risk_allele'+idx} placeholder="Аллель риска"
                                       ref={register({required: true})}
                                       defaultValue={'C'+index+idx}
                                />
                                {errors[index+'risk_allele'+idx] && <p className="error-text">{'*required'}</p>}
                            </span>
                            <span>
                                <label htmlFor={index+'normal_allele'+idx}>Аллель нормы</label>
                                <input type='text' name={index+'normal_allele'+idx} placeholder="Аллель нормы"
                                       ref={register({required: true})}
                                       defaultValue={'Z'+index+idx}
                                />
                                {errors[index+'normal_allele'+idx] && <p className="error-text">{'*required'}</p>}
                            </span>
                            <span>
                                <button type="button" onClick={() => handleRemove(idx)}>X</button>
                            </span>

                        </div>
                    )

                })
                }

            </React.Fragment>

        )
    }
    return(
        <div className="create-food-wrapped">
            <ModalSave open={openModal} onClose={()=>setOpenModal(false)} />
            <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                <div className='section-wrapped'>
                    <div>
                        <label htmlFor="user_id">User ID</label>
                        <input  type='text' name="user_id" placeholder="User ID" ref={register({ required: true })}
                            // defaultValue={'17'}
                        />
                    </div>
                    {errors.user_id &&  <p className="error-text">{'*required'}</p>}
                </div>
                <div className='section-wrapped'>
                    <h4>Наследственные заболевания</h4>
                    {dataIllness.map((data, idx) => {
                        return(
                            <div key={idx}>
                                <div><b>{data.title}</b></div>
                                {Result(idx)}
                            </div>

                        )
                    })}
                </div>
                <button  type="submit">Отправить</button>
            </form>
        </div>
    )
}
export default withGenobiosisService()(CreateInheritedIllness)