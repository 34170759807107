import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import {useForm} from "react-hook-form";
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import ModalSave from '../../modal-save'
import '../create-cabinet-section.sass'


const UpdatePsychogenetics = ({customerId, genobiosisService})=> {
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [psychogenetics, setPsychogenetics] = useState('')
    const [title, setTitle] = useState('')
    const {register, handleSubmit} = useForm();

    const testFunc = ()=> {
        genobiosisService.psychogeneticGet(customerId)
            .then((data)=>{
                const mainData = data.data.data
                console.log(mainData)
                setTitle(mainData)
                setPsychogenetics(
                    mainData.psychogeneticItems.map((val, idx)=> {
                        return(
                            <div key={idx}>
                                <div><b>{val.gen}</b></div>
                                <span>
                                    <label htmlFor={idx+'polymorphism'}>Полиморфизм</label>
                                    <input type='text' name={idx+'polymorphism'} placeholder="Полиморфизм"
                                           ref={register} defaultValue={val.polymorphism}/>
                                </span>
                                <span>
                                    <label htmlFor={idx+'result'}>Результат</label>
                                    <input type='text' name={idx+'result'} placeholder="Результат"
                                           ref={register} defaultValue={val.result}/>
                                </span>
                                <span>
                                    <label htmlFor={idx+'signs'}>Признаки</label>
                                    <input type='text' name={idx+'signs'} placeholder="Признаки"
                                           ref={register} defaultValue={val.signs}/>
                                </span>
                            </div>
                        )

                    })
                )
                setOpen(true)
            })
            .catch(err=>console.log(err))
    }
    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: customerId+'',
            psychogeneticItems:[]
        }
        const fields = (idx)=>{
            return{
                gen: title.psychogeneticItems[idx].gen,
                product_gen: title.psychogeneticItems[idx].product_gen,
                polymorphism: value[`${idx}polymorphism`],
                result: value[`${idx}result`],
                signs: value[`${idx}signs`]

            }
        }

        for(let i = 0; i<title.psychogeneticItems.length; i++){
            JSONData.psychogeneticItems.push(fields(i))
        }
        let data = JSON.stringify(JSONData)
        // console.log(JSONData)
        genobiosisService.psychogeneticUpdate(customerId,{data})
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }
    const closeConfirm = ()=>{
        setOpenModal(false)
        setOpen(false)
    }
    return (
        <div>
            <button onClick={testFunc}>Update Psychogenetics</button>
            <ModalSave open={openModal} onClose={closeConfirm} />
            <Modal show={open} onHide={()=>{setOpen(false)}} dialogClassName="modal-90w">
                <Modal.Header closeButton/>
                <div className="create-food-wrapped">
                    <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                        <div className='section-wrapped'>
                            <h4>Психогенетика</h4>
                            {psychogenetics}
                        </div>
                        <button  type="submit">Изменить</button>
                    </form>
                </div>
            </Modal>
        </div>

    )
}
export default withGenobiosisService()(UpdatePsychogenetics)