import React,{useState} from 'react'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import {useForm} from "react-hook-form";
import '../create-cabinet-section.sass'
import ModalSave from '../../modal-save'
const CreateFood = ({genobiosisService})=>{
    const [openModal, setOpenModal] = useState(false)

    const dataVit = [
        {
            vitamin: 'A',
            name: 'nameA',
            chance: 'chanceA',
            percent: 'percentA',
            recommendations: 'recommendationsA'
        },
        {
            vitamin: 'B9',
            name: 'nameB',
            chance: 'chanceB',
            percent: 'percentB',
            recommendations: 'recommendationsB'
        },
        {
            vitamin: 'C',
            name: 'nameC',
            chance: 'chanceC',
            percent: 'percentC',
            recommendations: 'recommendationsC'
        },
        {
            vitamin: 'E',
            name: 'nameE',
            chance: 'chanceE',
            percent: 'percentE',
            recommendations: 'recommendationsE'
        },
        {
            vitamin: 'D',
            name: 'nameD',
            chance: 'chanceD',
            percent: 'percentD',
            recommendations: 'recommendationsD'
        },
        {
            vitamin: 'B12',
            name: 'nameB12',
            chance: 'chanceB12',
            percent: 'percentB12',
            recommendations: 'recommendationsB12'
        },
        {
            vitamin: 'Fe',
            name: 'nameFe',
            chance: 'chanceFe',
            percent: 'percentFe',
            recommendations: 'recommendationsFe'
        },
        {
            vitamin: "Омега-3",
            name: 'nameO3',
            chance: 'chanceO3',
            percent: 'percentO3',
            recommendations: 'recommendationsO3'
        },
        {
            vitamin: 'Антиоксиданты',
            name: 'nameAn',
            chance: 'chanceAn',
            percent: 'percentAn',
            recommendations: 'recommendationsAn'
        },

    ]
    const dataFood = [
        {
            name: 'Восприятие кофеина',
            chance: 'sensitivity',
            recommendations: 'recommendationsCaffeinePerception',
            percent: 'percentCaffeinePerception'
        },
        {
            name: 'Непереносимость лактозы',
            chance: 'chanceLactoseIntolerance',
            recommendations: 'recommendationsLactoseIntolerance',
            percent: 'percentLactoseIntolerance'

        },
        {
            name: 'Непереносимость глютена',
            chance: 'chanceGlutenIntolerance',
            recommendations: 'recommendationsGlutenIntolerance',
            percent: 'percentGlutenIntolerance',

        },
        {
            name: 'Частые перекусы',
            chance: 'chanceSnacksNeeds',
            recommendations: 'recommendationsSnacksNeeds',
            percent: 'percentSnacksNeeds'
        },
        {
            name: 'Потребность в разгрузочных днях',
            chance: 'needsFastingDays',
            recommendations: 'recommendationsFastingDays',
            percent: 'percentFastingDays'

        },
    ]
    const { register, handleSubmit, errors } = useForm()


    const onSubmit = (value, event) => {
        event.preventDefault()
        const fields = (num,idx)=>{
            return{
                gene: value[`gene${num}${idx}`],
                polymorphism: value[`polymorphism${num}${idx}`],
                type: value[`type${num}${idx}`]
            }
        }


        let JSONData = {
            user_id: value.user_id,
            daily_calorie_determination: value.daily_calorie_determination,
            diet_type: value.diet_type,
            body_mass: value.body_mass,
            composition:{
                proteins: value.proteins,
                fats: value.fats,
                carbohydrates: value.carbohydrates
            },
            recommendations: value.recommendations,
            forbidden_products: value.forbidden_products,
            follow:{
                portion: value.portion,
                water: value.water,
                cooking: value.cooking,
                fats: value.follow_fats,
                flour_products: value.flour_products,
                protein_products: value.protein_products

            },
            restriction:{
                animal_fats: value.animal_fats,
                fast_carbohydrates: value.fast_carbohydrates,
                sausages: value.sausages
            },
            foodPlate:{
                vegetables: value.vegetables,
                healthy_proteins: value.healthy_proteins,
                fruit: value.fruit,
                whole_grains: value.whole_grains
            },
            vitamin:[
                {
                    name: value.nameA,
                    chance: value.chanceA,
                    percent:value.percentA,
                    recommendations:value.recommendationsA,
                    results:[
                        {
                            gene: value.gene00,
                            polymorphism: value.polymorphism00 ,
                            type: value.type00
                        }
                    ]
                },
                {

                    name: value.nameB,
                    chance: value.chanceB,
                    percent: value.percentB,
                    recommendations: value.recommendationsB,
                    results:[
                        {
                            gene: value.gene10,
                            polymorphism: value.polymorphism10,
                            type: value.type10
                        }
                    ]
                },
                {
                    name: value.nameC,
                    chance: value.chanceC,
                    percent:value.percentC,
                    recommendations: value.recommendationsC,
                    results:[
                        {
                            gene: value.gene20,
                            polymorphism: value.polymorphism20,
                            type: value.type20
                        }
                    ]
                },
                {
                    name: value.nameE,
                    chance: value.chanceE,
                    percent: value.percentE,
                    recommendations: value.recommendationsE,
                    results:[
                        {
                            gene: value.gene30,
                            polymorphism: value.polymorphism30,
                            type: value.type30
                        }
                    ]
                },
                {
                    name: value.nameD,
                    chance: value.chanceD,
                    percent: value.percentD,
                    recommendations: value.recommendationsD,
                    results:[
                        {
                            gene: value.gene40,
                            polymorphism: value.polymorphism40,
                            type: value.type40
                        }
                    ]
                },
                {
                    name: value.nameB12,
                    chance: value.chanceB12,
                    percent: value.percentB12,
                    recommendations: value.recommendationsB12,
                    results:[
                        {
                            gene: value.gene50,
                            polymorphism: value.polymorphism50,
                            type: value.type50
                        }
                    ]
                },
                {
                    name: value.nameFe,
                    chance: value.chanceFe,
                    percent: value.percentFe,
                    recommendations: value.recommendationsFe,
                    results:[
                        {
                            gene: value.gene60,
                            polymorphism: value.polymorphism60,
                            type: value.type60
                        }
                    ]
                },
                {
                    name: value.nameO3,
                    chance: value.chanceO3,
                    percent: value.percentO3,
                    recommendations: value.recommendationsO3,
                    results:[
                        {
                            gene: value.gene70,
                            polymorphism: value.polymorphism70,
                            type: value.type70
                        }
                    ]
                },
                {
                    name: value.nameAn,
                    chance: value.chanceAn,
                    percent: value.percentAn,
                    recommendations: value.recommendationsAn,
                    results:[
                        {
                            gene: value.gene80,
                            polymorphism: value.polymorphism80,
                            type: value.type80
                        }
                    ]
                },
            ],
            caffeinePerception: {
                sensitivity:value.sensitivity,
                recommendations:value.recommendationsCaffeinePerception,
                percent: value.percentCaffeinePerception,
                results:[
                    {
                        gene: value.gene0food0,
                        polymorphism: value.polymorphism0food0 ,
                        type: value.type0food0
                    }
                ]
            },
            lactoseIntolerance: {
                chance:value.chanceLactoseIntolerance,
                recommendations:value.recommendationsLactoseIntolerance,
                percent: value.percentLactoseIntolerance,
                results:[
                    {
                        gene: value.gene1food0,
                        polymorphism: value.polymorphism1food0 ,
                        type: value.type1food0
                    }
                ]
            },
            glutenIntolerance: {
                chance:value.chanceGlutenIntolerance,
                recommendations:value.recommendationsGlutenIntolerance,
                percent: value.percentGlutenIntolerance,
                results:[
                    {
                        gene: value.gene2food0,
                        polymorphism: value.polymorphism2food0 ,
                        type: value.type2food0
                    }
                ]
            },
            snacksNeeds: {
                chance:value.chanceSnacksNeeds,
                recommendations:value.recommendationsSnacksNeeds,
                percent: value.percentSnacksNeeds,
                results:[
                    {
                        gene: value.gene3food0,
                        polymorphism: value.polymorphism3food0 ,
                        type: value.type3food0
                    }
                ]
            },
            fastingDays: {
                need: value.needsFastingDays,
                recommendations:value.recommendationsFastingDays,
                percent: value.percentFastingDays,
                results:[
                    {
                        gene: value.gene4food0,
                        polymorphism: value.polymorphism4food0 ,
                        type: value.type4food0
                    }
                ]
            },

        }

        for(let i = 0; i<=dataVit.length; i++){
            for(let j = 1; j<20; j++){
                if(value[`gene${i}${j}`]){
                    JSONData.vitamin[i].results.push(fields(i,j))
                }

            }

        }
        const nextVal = (num,idx)=>{
            return{
                gene: value[`gene${num}food${idx}`],
                polymorphism: value[`polymorphism${num}food${idx}`],
                type: value[`type${num}food${idx}`]
            }
        }

        for(let j = 1; j<20; j++) {
            if (value[`gene${0}food${j}`]) {
                JSONData.caffeinePerception.results.push(nextVal(0, j))
            }
        }
        for(let j = 1; j<20; j++) {
            if (value[`gene${2}food${j}`]) {
                JSONData.lactoseIntolerance.results.push(nextVal(1, j))
            }
        }
        for(let j = 1; j<20; j++) {
            if (value[`gene${1}food${j}`]) {
                JSONData.glutenIntolerance.results.push(nextVal(2, j))
            }
        }

        for(let j = 1; j<20; j++) {
            if (value[`gene${3}food${j}`]) {
                JSONData.snacksNeeds.results.push(nextVal(3, j))
            }
        }
        for(let j = 1; j<20; j++){
            if(value[`gene${4}food${j}`]){
                JSONData.fastingDays.results.push(nextVal(4,j))
            }
        }


        let data = JSON.stringify(JSONData)
        console.log(JSONData)
        genobiosisService.foodCreate({
            data
        })
            .then(() => {
                setOpenModal(true)
                event.target.reset()
                }
            )
            .catch(err => console.log(err))


    }


    const Result = (index) =>{
        const [fields, setFields] = useState([{ value: null }]);
        function handleAdd() {
            const values = [...fields];
            values.push({ value: null });
            setFields(values);
        }
        function handleRemove(i) {
            const values = [...fields];
            values.splice(i, 1);
            setFields(values);
        }
        return(
            <React.Fragment>
                <div><i>Результаты</i></div>
                <span>
                    <button type="button" onClick={() => handleAdd()}>+</button>
                </span>
                {fields.map((field, idx) => {
                    return (
                         <div key={`${field}-${idx}`}>
                            <span>
                                <label htmlFor={'gene'+index+idx}>Ген</label>
                                <input type='text' name={'gene'+index+idx} placeholder="Ген"
                                       ref={register({required: true})}/>
                                {/*{`${errors}.gene${idx}${index}` && <p className="error-text">{'*required'}</p>}*/}
                            </span>
                            <span>
                                <label htmlFor={'polymorphism'+index+idx}>Полиморфизм</label>
                                <input type='text' name={'polymorphism'+index+idx} placeholder="Полиморфизм"
                                       ref={register({required: true})}/>
                                {/*{`${errors}.polymorphism${idx}${index}` && <p className="error-text">{'*required'}</p>}*/}
                            </span>
                            <span>
                                <label htmlFor={'type'+index+idx}>Ваш тип</label>
                                <input type='text' name={'type'+index+idx} placeholder="Ваш тип"
                                       ref={register({required: true})}/>
                                {/*{`${errors}.type${idx}${index}` && <p className="error-text">{'*required'}</p>}*/}
                            </span>
                            <span>
                                <button type="button" onClick={() => handleRemove(idx)}>X</button>
                            </span>

                         </div>
                    )

                })
            }

            </React.Fragment>

        )
    }


    return (
        <div className="create-food-wrapped">
            <ModalSave open={openModal} onClose={()=>setOpenModal(false)} />
            <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                <div className='section-wrapped'>
                    <div>
                        <label htmlFor="user_id">User ID</label>
                        <input  type='text' name="user_id" placeholder="User ID" ref={register({ required: true })}  />
                    </div>
                </div>
                {errors.user_id &&  <p className="error-text">{'*required'}</p>}
                <div className='section-wrapped'>
                    <h4>Общие данные</h4>
                    <div>
                        <label htmlFor="daily_calorie_determination">Ккалорий в день</label>
                        <input  type='number'  step="any" name="daily_calorie_determination" placeholder="Ккалорий в день" ref={register({ required: true })} />
                    </div>
                    {errors.daily_calorie_determination &&  <p className="error-text">{'*required'}</p>}
                    <div>
                        <label htmlFor="diet_type">Тип диеты</label>
                        <input type='text' name="diet_type" placeholder="Тип диеты" ref={register({ required: true })} />
                    </div>
                    {errors.diet_type &&  <p className="error-text">{'*required'}</p>}
                    <div>
                        <label htmlFor="body_mass">Индекс массы тела</label>
                        <input type='number'  step="any" name="body_mass" placeholder="Индекс массы тела" ref={register({ required: true })} />
                    </div>
                    {errors.body_mass &&  <p className="error-text">{'*required'}</p>}
                    <div>
                        <div><b>Состав и порции</b></div>
                        <span>
                            <label htmlFor="proteins">Белки %</label>
                            <input type='number'  step="any" name="proteins" placeholder="Белки" ref={register({ required: true })} />
                            {errors.proteins &&  <p className="error-text">{'*required'}</p>}
                        </span>
                        <span>
                            <label htmlFor="fats">Жиры %</label>
                            <input type='number'  step="any" name="fats" placeholder="Жиры" ref={register({ required: true })} />
                            {errors.fats &&  <p className="error-text">{'*required'}</p>}
                        </span>
                        <span>
                            <label htmlFor="carbohydrates">Углеводы %</label>
                            <input type='number'  step="any" name="carbohydrates" placeholder="Углеводы" ref={register({ required: true })} />
                            {errors.carbohydrates &&  <p className="error-text">{'*required'}</p>}
                        </span>
                    </div>
                    <div>
                        <label htmlFor="recommendations">Рекомендации</label>
                        <input type='text' name="recommendations" placeholder="Рекомендации" ref={register({ required: true })} />
                    </div>
                    {errors.recommendations &&  <p className="error-text">{'*required'}</p>}
                    <div>
                        <label htmlFor="forbidden_products">Запретные продукты</label>
                        <input type='text' name="forbidden_products" placeholder="Запретные продукты" ref={register({ required: true })} />
                    </div>
                    {errors.forbidden_products &&  <p className="error-text">{'*required'}</p>}
                    <div>
                        <div><b>Придерживаться</b></div>
                        <span>
                            <label htmlFor="portion">Порции</label>
                            <input type='text' name="portion" placeholder="Порции" ref={register({ required: true })} />
                            {errors.portion &&  <p className="error-text">{'*required'}</p>}
                        </span>
                        <span>
                            <label htmlFor="water">Вода</label>
                            <input type='text' name="water" placeholder="Вода" ref={register({ required: true })} />
                            {errors.water &&  <p className="error-text">{'*required'}</p>}
                        </span>
                        <span>
                            <label htmlFor="cooking">Приготовление</label>
                            <input type='text' name="cooking" placeholder="Приготовление" ref={register({ required: true })} />
                            {errors.cooking &&  <p className="error-text">{'*required'}</p>}
                        </span>
                        <span>
                            <label htmlFor="follow_fats">Жиры</label>
                            <input type='text' name="follow_fats" placeholder="Жиры" ref={register({ required: true })} />
                            {errors.follow_fats &&  <p className="error-text">{'*required'}</p>}
                        </span>
                        <span>
                            <label htmlFor="flour_products">Продукты из муки</label>
                            <input type='text' name="flour_products" placeholder="Продукты из муки" ref={register({ required: true })} />
                            {errors.flour_products &&  <p className="error-text">{'*required'}</p>}
                        </span>
                        <span>
                            <label htmlFor="protein_products">Белковые продукты</label>
                            <input type='text' name="protein_products" placeholder="Белковые продукты" ref={register({ required: true })} />
                            {errors.protein_products &&  <p className="error-text">{'*required'}</p>}
                        </span>
                    </div>
                    <div>
                        <div><b>Ограничить</b></div>
                        <span>
                            <label htmlFor="animal_fats">Животные жиры</label>
                            <input type='text' name="animal_fats" placeholder="Животные жиры" ref={register({ required: true })} />
                            {errors.animal_fats &&  <p className="error-text">{'*required'}</p>}
                        </span>
                        <span>
                            <label htmlFor="fast_carbohydrates">"Быстрые углеводы"</label>
                            <input type='text' name="fast_carbohydrates" placeholder="Быстрые углеводы" ref={register({ required: true })} />
                            {errors.fast_carbohydrates &&  <p className="error-text">{'*required'}</p>}
                        </span>
                        <span>
                            <label htmlFor="sausages">Колбасы любых видов</label>
                            <input type='text' name="sausages" placeholder="Колбасы любых видов" ref={register({ required: true })} />
                            {errors.sausages &&  <p className="error-text">{'*required'}</p>}
                        </span>
                    </div>
                    <div>
                        <div><b>Тарелка здорового питания</b></div>
                        <span>
                            <label htmlFor="vegetables">Овощи %</label>
                            <input type='number'  step="any" name="vegetables" placeholder="Овощи" ref={register({ required: true })} />
                            {errors.vegetables &&  <p className="error-text">{'*required'}</p>}
                        </span>
                        <span>
                            <label htmlFor="healthy_proteins">Полезные белки %</label>
                            <input type='number'  step="any" name="healthy_proteins" placeholder="Полезные белки" ref={register({ required: true })} />
                            {errors.healthy_proteins &&  <p className="error-text">{'*required'}</p>}
                        </span>
                        <span>
                            <label htmlFor="whole_grains">Цельнозерновые %</label>
                            <input type='number'  step="any" name="whole_grains" placeholder="Цельнозерновые" ref={register({ required: true })} />
                            {errors.whole_grains &&  <p className="error-text">{'*required'}</p>}
                        </span>
                        <span>
                            <label htmlFor="fruit">Фрукты %</label>
                            <input type='number'  step="any" name="fruit" placeholder="Фрукты" ref={register({ required: true })} />
                            {errors.fruit &&  <p className="error-text">{'*required'}</p>}
                        </span>
                    </div>
                </div>
                <div className='section-wrapped'>
                    <h4>Витамины и минералы</h4>
                    {dataVit.map((data, idx)=>{
                        return(
                            <div key={idx}>
                                <div><b>{data.vitamin}</b></div>
                                <span>
                                  <label htmlFor={data.name}>Название</label>
                                  <input type='text' name={data.name} placeholder="Название" ref={register({ required: true })} value={data.vitamin}/>
                                    {/*{`${errors}.${data.name}` &&  <p className="error-text">{'*required'}</p>}*/}
                                </span>
                                <span>
                                  <label htmlFor={data.chance}>Риск</label>
                                  <input type='text' name={data.chance} placeholder="Риск" ref={register({ required: true })} />
                                    {/*{`${errors}.${data.chance}` &&  <p className="error-text">{'*required'}</p>}*/}
                                </span>
                                <span>
                                  <label htmlFor={data.percent}>%</label>
                                  <input type='number'  step="any" name={data.percent} placeholder="%" ref={register({ required: true })} />
                                    {/*{`${errors}.${data.percent}` &&  <p className="error-text">{'*required'}</p>}*/}
                                </span>
                                <span>
                                  <label htmlFor={data.recommendations}>Расшифровка и рекомендации генетика</label>
                                  <input type='text' name={data.recommendations} placeholder="Расшифровка и рекомендации генетика" ref={register({ required: true })} />
                                    {/*{`${errors}.${data.recommendations}` &&  <p className="error-text">{'*required'}</p>}*/}
                                </span>
                                {Result(idx)}
                            </div>
                        )
                    })}
                </div>
                <div className='section-wrapped'>
                    <h4>Пищевые непереносимости и Пищевые привычки</h4>
                    {dataFood.map((data, idx) => {
                        return (
                            <div key={idx}>
                                <div><b>{data.name}</b></div>
                                <span>
                                  <label htmlFor={data.chance}>Риск</label>
                                  <input type='text' name={data.chance} placeholder="Риск" ref={register({ required: true })}  />
                                    {/*{`${errors}.${data.chance}` &&  <p className="error-text">{'*required'}</p>}*/}
                                </span>
                                <span>
                                  <label htmlFor={data.recommendations}>Расшифровка и рекомендации генетика</label>
                                  <input type='text' name={data.recommendations} placeholder="Расшифровка и рекомендации генетика" ref={register({ required: true })} />
                                    {/*{`${errors}.${data.recommendations}` &&  <p className="error-text">{'*required'}</p>}*/}
                                </span>
                                <span>
                                  <label htmlFor={data.percent}>%</label>
                                  <input type='number'  step="any" name={data.percent} placeholder="%" ref={register({ required: true })} />
                                    {/*{`${errors}.${data.percent}` &&  <p className="error-text">{'*required'}</p>}*/}
                                </span>
                                {Result(idx+'food')}
                            </div>
                        )
                    })}
                </div>
                <button  type="submit">Отправить</button>
            </form>
        </div>
    )
}
export default withGenobiosisService()(CreateFood)