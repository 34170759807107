const initialState = {
    customers: [],
    loading: true,
    error: null,
    status:[],
    loadingS: true,
    errorS: null,
    customer: [],
    loadingC: true,
    errorC: null,
    statusCustomer: [],
    loadingStatus: true,
    errorStatus: null,
    customerMainData: [],
    loadingMainData: true,
    errorMainData: null,
    subscriptionSuccess: null,
    customerFoodLk: [],
    loadingFoodLk:true,
    errorFoodLk: null
};

const reducers = (state = initialState, action) => {

    switch (action.type) {
        case 'CUSTOMERS_REQUEST':
            return {
                ...state,
                customers: [],
                loading: true,
                error: null,

            };

        case 'CUSTOMERS_SUCCESS':
            return {
                ...state,
                customers: action.payload,
                loading: false,
                error: null,

            };

        case 'CUSTOMERS_FAILURE':
            return {
                ...state,
                customers: [],
                loading: false,
                error: action.payload,

            };
        case 'STATUS_REQUEST':
            return {
                ...state,
                loadingS: true,
                errorS: null,
                status: []
            };

        case 'STATUS_SUCCESS':
            return {
                ...state,
                loadingS: false,
                errorS: null,
                status: action.payload,
            };

        case 'STATUS_FAILURE':
            return {
                ...state,
                loadings: false,
                errorS: action.payload,
                status: []
            };
        case 'CUSTOMER_REQUEST':
            return {
                ...state,
                loadingC: true,
                errorC: null,
                customer: []
            };

        case 'CUSTOMER_SUCCESS':
            return {
                ...state,
                loadingC: false,
                errorC: null,
                customer: action.payload,
            };

        case 'CUSTOMER_FAILURE':
            return {
                ...state,
                loadingC: false,
                errorC: action.payload,
                customer: []
            };
        case 'CUSTOMER_STATUS_REQUEST':
            return {
                ...state,
                loadingStatus: true,
                errorStatus: null,
                statusCustomer: []
            };

        case 'CUSTOMER_STATUS_SUCCESS':
            return {
                ...state,
                loadingStatus: false,
                errorStatus: null,
                statusCustomer: action.payload,
            };

        case 'CUSTOMER_STATUS_FAILURE':
            return {
                ...state,
                loadingStatus: false,
                errorStatus: action.payload,
                statusCustomer: []
            };
        case 'CUSTOMER_MAIN_DATA_REQUEST':
            return {
                ...state,
                loadingMainData: true,
                errorMainData: null,
                customerMainData: []
            };

        case 'CUSTOMER_MAIN_DATA_SUCCESS':
            return {
                ...state,
                loadingMainData: false,
                errorMainData: null,
                customerMainData: action.payload,
            };

        case 'CUSTOMER_MAIN_DATA_FAILURE':
            return {
                ...state,
                loadingMainData: false,
                errorMainData: action.payload,
                customerMainData: []
            };
        // case 'LK_FOOD_REQUEST':
        //     return {
        //         ...state,
        //         loadingFoodLk:true,
        //         errorFoodLk: null,
        //         customerFoodLk: []
        //     };
        //
        // case 'LK_FOOD_SUCCESS':
        //     return {
        //         ...state,
        //         loadingFoodLk: false,
        //         errorFoodLk: null,
        //         customerFoodLk: action.payload,
        //     };
        //
        // case 'LK_FOOD_FAILURE':
        //     return {
        //         ...state,
        //         loadingFoodLk: false,
        //         errorFoodLk: action.payload,
        //         customerFoodLk: []
        //     };

        default:
            return state;
    }
};

export default reducers;
