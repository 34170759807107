export const illnessData = [
    {
        name: 'Депрессия',
        link:'depression',
        illness_chance_middle:[0, 0, 3, 10, 18, 19, 17, 14, 9, 2, 1],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:10},
            {suggestedMax:40}
        ]
    },
    {
        name: 'Алкоголизм',
        link:'alcohol',
        illness_chance_middle:[0, 0.05, 7, 9, 8, 4, 2, 1, 0.05, 0.05, 0],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:10},
            {suggestedMax:40}
        ]
    },
    {
        name: 'Целиакия',
        link:'celiac',
        illness_chance_middle:[0, 0.8, 0.7, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.5},
            {suggestedMax:2.0}
        ]
    },
    {
        name: 'Болезнь Крона',
        link:'crohn',
        illness_chance_middle:[0, 0.005, 0.015, 0.017, 0.01, 0.007, 0.004, 0.004, 0.007, 0.01, 0.012],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.02},
            {suggestedMax:0.04}
        ]
    },
    {
        name: 'Рак поджелудочной железы',
        link:'pancreatic-cancer',
        illness_chance_middle:[0, 0, 0, 0, 0, 0.001, 0.002, 0.005, 0.005, 0.004, 0.004],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.01},
            {suggestedMax:0.02}
        ]
    },
    {
        name: 'Язвенный колит',
        link:'ulcerative-colitis',
        illness_chance_middle:[0, 0.001, 0.005, 0.01, 0.012, 0.012, 0.012, 0.011, 0.01, 0.01, 0.01],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.01},
            {suggestedMax:0.03}
        ]
    },
    {
        name: 'Ишемическая болезнь сердца',
        link:'coronary-heart-disease',
        illness_chance_middle:[0, 0, 0, 1, 1, 2, 8, 14, 21, 24, 25],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:10},
            {suggestedMax:30}
        ]
    },
    {
        name: 'Мерцательная аритмия',
        link:'atrial-fibrillation',
        illness_chance_middle:[0, 0, 0, 0, 0, 0, 1, 2, 9, 12, 14],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:10},
            {suggestedMax:30}
        ]
    },
    {
        name: 'Венозная тромбоэмболия',
        link:'tela',
        illness_chance_middle:[0, 0, 0, 0, 0.1, 0.1, 0.2, 0.4, 0.7, 0.9, 0.9],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.5},
            {suggestedMax:1.5}
        ]
    },
    {
        name: 'Ишемический инсульт',
        link:'ischaemic-stroke',
        illness_chance_middle:[0, 0, 0, 0, 1, 2, 3, 5, 10, 14, 16],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:10},
            {suggestedMax:30}
        ]
    },
    {
        name: 'Рассеянный склероз',
        illness_chance_middle:[0, 0, 0.001, 0.004, 0.008, 0.13, 0.19, 0.19, 0.12, 0.007, 0.003],
        link:'disseminated-sclerosis',
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.1},
            {suggestedMax:0.5}
        ]
    },
    {
        name: 'Болезнь Паркинсона',
        illness_chance_middle:[0, 0, 0, 0, 0, 0.1, 0.2, 0.6, 1.1, 1.9, 2.1],
        link:'parkinson',
        chart_percent:[
            {suggestedMin:0},
            {stepSize:1},
            {suggestedMax:5}
        ]
    },
    {
        name: 'Болезнь Альцгеймера',
        link:'alzheimer',
        illness_chance_middle:[0, 0, 0, 0, 0, 0, 1, 2, 6, 15, 20],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:10},
            {suggestedMax:30}
        ]
    },
    {
        name: 'Ожирение',
        link:'obesity',
        illness_chance_middle:[0, 0, 5, 9, 11, 18, 24, 28, 28, 28, 28],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:10},
            {suggestedMax:50}
        ]
    },
    {
        name: 'Сахарный диабет 2 типа',
        link:'type-2-diabetes',
        illness_chance_middle:[0, 1, 2, 4, 5, 7, 11, 11, 10, 10, 10],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:5},
            {suggestedMax:25}
        ]
    },
    {
        name: 'Сахарный диабет 1 типа',
        link:'type-1-diabetes',
        illness_chance_middle:[0, 0.01, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.02},
            {suggestedMax:0.04}
        ]
    },
    {
        name: 'Астма',
        link:'asthma',
        illness_chance_middle:[0, 0.4, 0.3, 0.25, 0.2, 0.25, 0.4, 0.6, 0.8, 0.8, 0.7],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.5},
            {suggestedMax:1.5}
        ]
    },
    {
        name: 'Ревматоидный артрит',
        link:'rheumatoid-arthritis',
        illness_chance_middle:[0, 0, 0, 0, 0.1, 0.2, 0.4, 0.75, 1, 1, 0.9],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.5},
            {suggestedMax:2.0}
        ]
    },
    {
        name: 'Системная красная волчанка',
        link:'disseminated-lupus-erythematosus',
        illness_chance_middle:[0, 0, 0.002, 0.005, 0.01, 0.015, 0.019, 0.02, 0.022, 0.025, 0.027],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.02},
            {suggestedMax:0.04}
        ]
    },
    {
        name: 'Системный склероз (склеродермия)',
        link:'systemic-sclerosis',
        illness_chance_middle:[0, 0, 0.0002, 0.0007, 0.0012, 0.0017, 0.002, 0.0018, 0.001, 0.0002, 0],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.002},
            {suggestedMax:0.004}
        ]
    },
    {
        name: 'Псориатический артрит',
        link:'psoriatic-arthritis',
        illness_chance_middle:[0, 0, 0.02, 0.05, 0.19, 0.29, 0.32, 0.3, 0.2, 0.12, 0.09],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.2},
            {suggestedMax:0.6}
        ]
    },
    {
        name: 'Хронические болезни почек',
        link:'chronic-kidney-disease',
        illness_chance_middle:[0, 0, 0, 0, 0, 2, 4, 10, 20, 30, 35],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:10},
            {suggestedMax:40}
        ]
    },
    {
        name: 'Колоректальный рак',
        link:'colorectal-cancer',
        illness_chance_middle:[0, 0, 0, 0, 0, 0.001, 0.004, 0.011, 0.024, 0.027, 0.027,
        ],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.02},
            {suggestedMax:0.06}
        ]
    },
    {
        name: 'Рак легких',
        link:'lung-cancer',
        illness_chance_middle:[0, 0, 0, 0.02, 0.03, 0.03, 0.05, 0.12, 0.24, 0.26, 0.21],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.1},
            {suggestedMax:0.3}
        ]
    },
    {
        name: 'Меланома',
        link:'melanoma',
        illness_chance_middle:[0, 0, 0, 0.0001, 0.0003, 0.0003, 0.0004, 0.0019, 0.0027, 0.003, 0.003],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.002},
            {suggestedMax:0.004}
        ]
    },
    {
        name: 'Шизофрения',
        link:'schizophrenia',
        illness_chance_middle:[0, 0, 0.06, 0.15, 0.18, 0.13, 0.09, 0.05, 0.03, 0.02, 0.02],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.1},
            {suggestedMax:0.3}
        ]
    },
    {
        name: 'Гнездная алопеция',
        link:'alopecia-areata',
        illness_chance_middle:[0, 1.5, 1.5, 2, 2.3, 2, 1, 0.5, 0.2, 0.2, 0.2,],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:2},
            {suggestedMax:6}
        ]
    },
    {
        name: 'Хронические воспалительные заболевания кишечника',
        link:'сhronic-inflammatory-bowel-disease',
        illness_chance_middle:[0, 0.1, 0.2, 0.35, 0.41, 0.45, 0.49, 0.52, 0.53, 0.55, 0.57],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:0.5},
            {suggestedMax:1.5}
        ]
    },
    {
        name: 'Аллергические заболевания',
        link:'allergic-diseases',
        illness_chance_middle:[0, 52, 54, 52, 50, 45, 40, 35, 30, 27, 25,],
        chart_percent:[
            {suggestedMin:0},
            {stepSize:50},
            {suggestedMax:100}
        ]
    }
]