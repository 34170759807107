import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import {useForm} from "react-hook-form";
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import ModalSave from '../../modal-save'
import {illnessData} from "./illness-data";
import '../create-cabinet-section.sass'

const UpdateIllness = ({customerId, genobiosisService})=>{
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [illness, setIllness] = useState('')
    const { register, handleSubmit } = useForm();
    const testFunc = ()=>{
        genobiosisService.illnessGet(customerId)
            .then((data)=> {

                const mainData = data.data.data
                console.log(mainData)
                setIllness(
                    mainData.illnessItems.map((data, idx)=>{
                        return(
                            <div key={idx}>
                                <div className="article-name"><b>{data.name}</b></div>
                                <div>
                                    <label htmlFor={'chance'+idx}>Ваш Риск</label>
                                    <input type='number'  step="any" name={'chance'+idx} placeholder="Ваш Риск"
                                           ref={register}  defaultValue={data.chance}/>
                                </div>
                                <div>
                                    <label htmlFor={'middle_chance'+idx}>Средний Риск:</label>
                                    <input type='text' name={'middle_chance'+idx} placeholder="Средний Риск"
                                           ref={register({required: true})} defaultValue={data.illness_probability[0].middle_chance}/>
                                </div>
                                <div>
                                    <label htmlFor={'recommendations'+idx}>Рекомендации:</label>
                                    <input type='text' name={'recommendations'+idx} placeholder="Рекомендации"
                                           ref={register({required: true})} defaultValue={data.recommendations}/>
                                </div>
                                <div>
                                    <div><b>Ваш риск (диаграмма)</b></div>
                                    {data.illness_probability[0].illness_chance.map((val, index) => {
                                       return(
                                           <span key={index}>
                                               <label htmlFor={idx+'illness_chance'+index}>{index+'0'}</label>
                                               <input type='number'  step="any" name={idx+'illness_chance'+index} placeholder="%"
                                                      ref={register} defaultValue={val}/>
                                           </span>
                                       )
                                    })
                                    }

                                </div>
                            </div>
                        )
                    })
                )
                setOpen(true)
            })
            .catch(err=>console.log(err))

    }
    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: customerId+'',
            illnessItems:[

            ]
        }
        const fields = (idx)=>{
            return{
                name: illnessData[idx].name,
                chance: value[`chance${idx}`],
                recommendations: value[`recommendations${idx}`],
                illness_probability: [
                    {
                        middle_chance: value[`middle_chance${idx}`],
                        illness_chance: [
                            value[`${idx}illness_chance${0}`],
                            value[`${idx}illness_chance${1}`],
                            value[`${idx}illness_chance${2}`],
                            value[`${idx}illness_chance${3}`],
                            value[`${idx}illness_chance${4}`],
                            value[`${idx}illness_chance${5}`],
                            value[`${idx}illness_chance${6}`],
                            value[`${idx}illness_chance${7}`],
                            value[`${idx}illness_chance${8}`],
                            value[`${idx}illness_chance${9}`],
                            value[`${idx}illness_chance${10}`],
                        ],
                        illness_chance_middle: illnessData[idx].illness_chance_middle,
                        link: illnessData[idx].link,
                        chart_percent: illnessData[idx].chart_percent

                    }
                ]
            }
        }
        for(let i = 0; i<=illnessData.length; i++){
            if (value[`chance${i}`]) {
                JSONData.illnessItems.push(fields(i))
            }

        }

        let data = JSON.stringify(JSONData)
        console.log(JSONData)
        genobiosisService.illnessUpdate(customerId,{data})
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }
    // const testFunc = ()=>{
    //     setOpen(true)
    // }
    const closeConfirm = ()=>{
        setOpenModal(false)
        setOpen(false)
    }
    return (
        <div><
            button onClick={testFunc}>Update Illness</button>
            <ModalSave open={openModal} onClose={closeConfirm} />
            <Modal show={open} onHide={()=>{setOpen(false)}} dialogClassName="modal-90w">
                <Modal.Header closeButton/>
                <div className="create-food-wrapped">
                    <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                        <div className='section-wrapped'>
                            <h4>Болезни</h4>
                            {illness}
                        </div>
                        <button  type="submit">Изменить</button>
                    </form>
                </div>
            </Modal>
        </div>
    )
}
export default withGenobiosisService()(UpdateIllness)