import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import {useForm} from "react-hook-form";
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import ModalSave from '../../modal-save'
import { Tabs, Tab }from 'react-bootstrap'
import {pharmacogeneticsData} from "./pharmacogenetics-data";
import '../create-cabinet-section.sass'

const UpdatePharmacogenetics = ({customerId, genobiosisService})=> {
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [pharmacogenetics, setPharmacogenetics] = useState('')
    const [title, setTitle] = useState('')
    const {register, handleSubmit} = useForm();

    const testFunc = ()=>{
        genobiosisService.pharmacogeneticGet(customerId)
            .then((data)=> {
                const mainData = data.data.data
                console.log(mainData)
                setTitle(mainData)
                setPharmacogenetics(
                    mainData.groups.map((val, idx)=> {
                        return(
                            <Tab key={idx} eventKey={val.name} title={val.name}>
                                {val.medicaments.map((data, id)=>{
                                    return(
                                        <div key={id}>
                                            <div className="article-name"><b>{data.name}</b></div>
                                            {data.gens.map((values, num)=>{
                                                return(
                                                    <span key={num} className="span-padding-15 ">
                                                        <label htmlFor={idx+'-'+values.name+'-'+id}>{values.name}</label>
                                                        <input type='checkbox' name={idx+'-'+values.name+'-'+id} ref={register} defaultChecked={values.status}/>
                                                    </span>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </Tab>
                        )

                    })
                )
                setOpen(true)
            })
            .catch(err=>console.log(err))

    }
    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: customerId+'',
            groups:[]
        }
        const fieldsGroup = (idx)=>{
            return{
                name: title.groups[idx].name,
                medicaments: []
            }
        }
        const fieldsMedicaments = (idx, id)=>{
            return{
                name: title.groups[idx].medicaments[id].name,
                gens: []
            }
        }
        const fields = (idx, id, num)=>{
            return{
                name: title.groups[idx].medicaments[id].gens[num].name,
                status: value[`${idx}-${title.groups[idx].medicaments[id].gens[num].name}-${id}`]

            }

        }

        for(let i = 0; i<title.groups.length; i++){
            JSONData.groups.push(fieldsGroup(i))
            for(let j = 0; j<title.groups[i].medicaments.length; j++) {
                JSONData.groups[i].medicaments.push(fieldsMedicaments(i,j))
                for (let k = 0; k<pharmacogeneticsData[i].medicaments[j].gens.length ; k++) {
                    JSONData.groups[i].medicaments[j].gens.push(fields(i,j,k))
                }
            }
        }
        let data = JSON.stringify(JSONData)
        // console.log(JSONData)
        genobiosisService.pharmacogeneticUpdate(customerId,{data})
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }

    const closeConfirm = ()=>{
        setOpenModal(false)
        setOpen(false)
    }

    return (
        <div>
            <button onClick={testFunc}>Update Pharmacogenetics</button>
            <ModalSave open={openModal} onClose={closeConfirm} />
            <Modal show={open} onHide={()=>{setOpen(false)}} dialogClassName="modal-90w">
                <Modal.Header closeButton/>
                <div className="create-food-wrapped">
                    <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                        <div className='section-wrapped'>
                            <h4>Фармакогенетика</h4>
                            <Tabs defaultActiveKey="Антидепрессанты" id="uncontrolled-tab-example">
                                {pharmacogenetics}
                            </Tabs>
                        </div>
                        <button  type="submit">Изменить</button>
                    </form>
                </div>
            </Modal>
        </div>

    )
}
export default withGenobiosisService()(UpdatePharmacogenetics)