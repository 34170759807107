import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import {useForm} from "react-hook-form";
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import ModalSave from '../../modal-save'
import {optionLevel} from "./physical-skills-data";
import '../create-cabinet-section.sass'

const UpdatePhysicalSkills = ({customerId, genobiosisService})=> {
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [physicalSkills, setPhysicalSkills] = useState('')
    const {register, handleSubmit} = useForm();
    const [selected, setSelected]=useState('')

    const testFunc = ()=> {
        genobiosisService.physicalskillsGet(customerId)
            .then((data)=>{
                const mainData = data.data.data
                console.log(mainData)

                setPhysicalSkills(
                    <div>
                        <div>
                            <div><b>Потребность в физической активности</b></div>
                            <span>
                                    <label htmlFor={'need_level'}>Уровень</label>
                                    <select name="need_level" ref={register}>
                                        {optionLevel.map((data, idx)=>{
                                            return <option key={idx} selected={data.level===mainData.need_level?true:false} value={data.level}>{data.level}</option>
                                        })}
                                    </select>
                                </span>
                            <div>
                                <label htmlFor={'need_description'}>Pекомендации</label>
                                <input type='text' name={'need_description'} placeholder="Рекомендации"
                                       ref={register({required: true})}
                                        defaultValue={mainData.need_description}
                                />
                            </div>
                        </div>
                        <div>
                            <div><b>Оптимальная интенсивность физической активности</b></div>
                            <span>
                                    <label htmlFor={'intensity_level'}>Уровень</label>
                                    <select name="intensity_level" ref={register}>
                                        {optionLevel.map((data, idx)=>{
                                            return <option key={idx} selected={data.level===mainData.intensity_level?true:false} value={data.level}>{data.level}</option>
                                        })}
                                    </select>
                            </span>
                            <span>
                                <label htmlFor={'intensity_value'}>Значение</label>
                                <input type='text' name={'intensity_value'} placeholder="Значение"
                                       ref={register({required: true})}
                                       defaultValue={mainData.intensity_value}
                                />
                            </span>
                            <div>
                                <label htmlFor={'intensity_description'}>Pекомендации</label>
                                <input type='text' name={'intensity_description'} placeholder="Рекомендации"
                                       ref={register({required: true})}
                                       defaultValue={mainData.intensity_description}
                                />
                            </div>
                        </div>
                        <div>
                            <div><b>Параметри физической активности</b></div>
                            <span>
                                    <label htmlFor={'power'}>Сила</label>
                                    <select name="power" ref={register}>
                                        {optionLevel.map((data, idx)=>{
                                            return <option key={idx} selected={data.level===mainData.parameters[0].power?true:false} value={data.level}>{data.level}</option>
                                        })}
                                    </select>
                            </span>
                            <span>
                                    <label htmlFor={'endurance'}>Выносливость</label>
                                    <select name="endurance" ref={register}>
                                        {optionLevel.map((data, idx)=>{
                                            return <option key={idx} selected={data.level===mainData.parameters[0].endurance?true:false} value={data.level}>{data.level}</option>
                                        })}
                                    </select>
                            </span>
                            <span>
                                    <label htmlFor={'motivation'}>Мотивация</label>
                                    <select name="motivation" ref={register}>
                                        {optionLevel.map((data, idx)=>{
                                            return <option key={idx} selected={data.level===mainData.parameters[0].motivation?true:false} value={data.level}>{data.level}</option>
                                        })}
                                    </select>
                            </span>
                        </div>
                    </div>
                )
                setOpen(true)
            })
            .catch(err=>console.log(err))
    }
    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: customerId + '',
            need_description: value.need_description,
            need_level:value.need_level,
            intensity_description:value.intensity_description,
            intensity_level:value.intensity_level,
            intensity_value:value.intensity_value,
            parameters:[
                {
                    power:value.power,
                    endurance:value.endurance,
                    motivation:value.motivation
                },
            ]
        }
        let data = JSON.stringify(JSONData)
        // console.log(JSONData)
        genobiosisService.physicalskillsUpdate(customerId,{data})
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }
    const closeConfirm = ()=>{
        setOpenModal(false)
        setOpen(false)
    }
    return (
        <div>
            <button onClick={testFunc}>Update PhysicalSkills</button>
            <ModalSave open={openModal} onClose={closeConfirm} />
            <Modal show={open} onHide={()=>{setOpen(false)}} dialogClassName="modal-90w">
                <Modal.Header closeButton/>
                <div className="create-physical-wrapped">
                    <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                        <div className='section-wrapped'>
                            <h4>Физические способности</h4>
                            {physicalSkills}
                        </div>
                        <button  type="submit">Изменить</button>
                    </form>
                </div>
            </Modal>
        </div>

    )
}
export default withGenobiosisService()(UpdatePhysicalSkills)