import React from 'react';
import GetAllStatus from '../get-all-status'
import DeleteBtn from '../delete-customer'
import UpdateFood from '../create-cabinet-section/food/update-food'
import UpdateSkin from '../create-cabinet-section/skin/update-skin'
import UpdateIllness from '../create-cabinet-section/illness/update-illness'
import UpdateInheritedIllness from '../create-cabinet-section/inherited-illness/update-inherited-illness'
import UpdatePharmacogenetics from '../create-cabinet-section/pharmacogenetics/update-pharmacogenetics'
import UpdatePsychogenetics from '../create-cabinet-section/psychogenetics/update-psychogenetics'
import UpdateChronotypes from '../create-cabinet-section/chronotypes/update-chronotypes'
import UpdatePhysicalSkills from '../create-cabinet-section/physical-skills/update-physical-skills'
import UpdateCustomer from '../update-customer'
import UpdateOrigin from '../create-cabinet-section/origin/update-origin'
const GetCustomer = ({ customer }) => {
    const {id, surname, given_name} = customer;

    return (
                    <tr>
                        <td>{id}</td>
                        <td>{surname}</td>
                        <td>{given_name}</td>
                        <td><GetAllStatus customerId={id}/></td>
                        <td><UpdateCustomer customerId={id}/></td>
                        <td><DeleteBtn customerId={id}/></td>
                        <td><UpdateFood customerId={id}/></td>
                        <td><UpdateSkin customerId={id}/></td>
                        <td><UpdateIllness customerId={id}/></td>
                        <td><UpdateInheritedIllness customerId={id}/></td>
                        <td><UpdatePharmacogenetics customerId={id}/></td>
                        <td><UpdatePsychogenetics customerId={id}/></td>
                        <td><UpdateChronotypes customerId={id}/></td>
                        <td><UpdatePhysicalSkills customerId={id}/></td>
                        <td><UpdateOrigin customerId={id}/></td>
                    </tr>

    );
};

export default GetCustomer;