const customersLoaded = (customers) =>{
    return {
        type: 'CUSTOMERS_SUCCESS',
        payload: customers
    }
}
const customersRequested = ()=>{
    return {
        type: 'CUSTOMERS_REQUEST'
    }
}
const customersError = (error)=>{
    return {
        type: 'CUSTOMERS_FAILURE',
        payload: error
    }
}


const fetchCustomers = (genobiosisService, dispatch, page) => () => {
    dispatch(customersRequested());
    genobiosisService.getCustomerLink(page)
        .then((data) => dispatch(customersLoaded(data)))
        .catch((err) => dispatch(customersError(err)));
};


const statusLoaded = (status) =>{
    return {
        type: 'STATUS_SUCCESS',
        payload: status
    }
}
const statusRequested = ()=>{
    return {
        type: 'STATUS_REQUEST'
    }
}
const statusError = (error)=>{
    return {
        type: 'STATUS_FAILURE',
        payload: error
    }
}
const fetchStatus = (genobiosisService, dispatch) => () => {
    dispatch(statusRequested());
    genobiosisService.getStatus()
        .then((data) => dispatch(statusLoaded(data)))
        .catch((err) => dispatch(statusError(err)));
};

const customerDataLoaded = (customer) =>{
    return {
        type: 'CUSTOMER_SUCCESS',
        payload: customer
    }
}
const customerDataRequested = ()=>{
    return {
        type: 'CUSTOMER_REQUEST'
    }
}
const customerDataError = (error)=>{
    return {
        type: 'CUSTOMER_FAILURE',
        payload: error
    }
}
const fetchCustomerData = (genobiosisService, dispatch) => () => {
    dispatch(customerDataRequested());
    genobiosisService.getCustomerData()
        .then((data) => dispatch(customerDataLoaded(data)))
        .catch((err) => dispatch(customerDataError(err)));
};
const customerStatusDataLoaded = (customerStatus) =>{
    return {
        type: 'CUSTOMER_STATUS_SUCCESS',
        payload: customerStatus
    }
}
const customerStatusDataRequested = ()=>{
    return {
        type: 'CUSTOMER_STATUS_REQUEST'
    }
}
const customerStatusDataError = (error)=>{
    return {
        type: 'CUSTOMER_STATUS_FAILURE',
        payload: error
    }
}
const fetchCustomerStatusData = (genobiosisService, dispatch) => () => {
    dispatch(customerStatusDataRequested());
    genobiosisService.getCustomerStatus()
        .then((data) => dispatch(customerStatusDataLoaded(data.status[0].id)))
        .catch((err) => dispatch(customerStatusDataError(err)));
};
const customerStatusDataMainLoaded = (customerMainData) =>{
    return {
        type: 'CUSTOMER_MAIN_DATA_SUCCESS',
        payload: customerMainData
    }
}
const customerStatusDataMainRequested = ()=>{
    return {
        type: 'CUSTOMER_MAIN_DATA_REQUEST'
    }
}
const customerStatusDataMainError = (error)=>{
    return {
        type: 'CUSTOMER_MAIN_DATA_FAILURE',
        payload: error
    }
}
const fetchCustomerStatusMainData = (genobiosisService, dispatch) => () => {
    dispatch(customerStatusDataMainRequested());
    genobiosisService.getCustomerMainData()
        .then((data) => dispatch(customerStatusDataMainLoaded(data)))
        .catch((err) => {
            dispatch(customerStatusDataMainError(err))
            console.log(err)
        });
};
// const customerLKFoodLoaded = (customerLKFood) =>{
//     return {
//         type: 'LK_FOOD_SUCCESS',
//         payload: customerLKFood
//     }
// }
// const customerLKFoodRequested = ()=>{
//     return {
//         type: 'LK_FOOD_REQUEST'
//     }
// }
// const customerLKFoodError = (error)=>{
//     return {
//         type: 'LK_FOOD_FAILURE',
//         payload: error
//     }
// }
// const fetchCustomerLKFood = (genobiosisService, dispatch, id) => () => {
//     dispatch(customerLKFoodRequested());
//     genobiosisService.foodGet(id)
//         .then((data) => dispatch(customerLKFoodLoaded(data)))
//         .catch((err) => {
//             dispatch(customerLKFoodError(err))
//             console.log(err)
//         });
// };

export {
    fetchCustomers,
    fetchStatus,
    statusError,
    fetchCustomerData,
    fetchCustomerStatusData,
    fetchCustomerStatusMainData,
    // fetchCustomerLKFood
};