import React,{useState, useEffect} from 'react'
// import { connect } from 'react-redux';
import withGenobiosisService from "../hoc/with-genobiosis-service";

const CustomersList =({genobiosisService})=> {
    const [customers, setCustomers]=useState([])
    const [page, setPage]=useState(1)
    useEffect(()=>{
        changePage(page)
    },[])
    const changePage =(num)=>{
        genobiosisService.getCustomerLink(num)
            .then((data) => {
                console.log(data)
                setCustomers(data)
            })
            .catch((err) => console.log(err));
    }
    return(
        <section>
            <h2>Список клиентов</h2>
            <div style={{overflow: 'auto' }} className='table-list table-responsive-lg'>
                <table className="table table-striped table-sm table-customer">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Фамилия</th>
                        <th>Имя</th>
                        <th>Отчество</th>
                        <th>Пол</th>
                        <th>Тип паспорта</th>
                        <th>Email</th>
                        <th>Телефон</th>
                        <th>Дата рождения</th>
                        <th>Адрес доставки паспорта</th>
                        <th>Адрес регистрации</th>
                        <th>Паспортные данные</th>
                        <th>Язык</th>
                        <th>Статус готовности</th>
                    </tr>
                    </thead>
                    <tbody>
                    {customers.map((customer, idx) => {
                        return (
                        <tr key={idx}>
                            <td>{customer.gen_id}</td>
                            <td>{customer.surname}</td>
                            <td>{customer.given_name}</td>
                            <td>{customer.patronymic}</td>
                            <td>{customer.sex}</td>
                            <td>{customer.passport_type}</td>
                            <td>{customer.email}</td>
                            <td>{customer.phone}</td>
                            <td>{customer.birthday}</td>
                            <td>{customer.deliver_address}</td>
                            <td>{customer.register_address}</td>
                            <td>{customer.passport_data}</td>
                            <td>{customer.lang}</td>
                            <td>{customer.statuses}</td>
                        </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <button onClick={()=>changePage(1)}>1</button>
            <button onClick={()=>changePage(2)}>2</button>
        </section>
    )
}
export default withGenobiosisService()(CustomersList);