import React,{ useState } from 'react'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import {useForm} from "react-hook-form";
import '../create-cabinet-section.sass'
import ModalSave from '../../modal-save'
import {pharmacogeneticsData} from "./pharmacogenetics-data";
import { Tabs, Tab }from 'react-bootstrap'


const CreatePharmacogenetics = ({ genobiosisService })=> {
    const [openModal, setOpenModal] = useState(false)
    const { register, handleSubmit, errors } = useForm()

    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: value.user_id,
            groups:[]
        }
        const fieldsGroup = (idx)=>{
            return{
                name: pharmacogeneticsData[idx].name,
                medicaments: []
            }
        }
        const fieldsMedicaments = (idx, id)=>{
            return{
                name: pharmacogeneticsData[idx].medicaments[id].name,
                gens: []
            }
        }
        const fields = (idx, id, num)=>{
            return{
                name: pharmacogeneticsData[idx].medicaments[id].gens[num].name,
                status: value[`${idx}-${pharmacogeneticsData[idx].medicaments[id].gens[num].name}-${id}`]

            }

        }

        for(let i = 0; i<pharmacogeneticsData.length; i++){
            JSONData.groups.push(fieldsGroup(i))
            for(let j = 0; j<pharmacogeneticsData[i].medicaments.length; j++) {
                JSONData.groups[i].medicaments.push(fieldsMedicaments(i,j))
                for (let k = 0; k<pharmacogeneticsData[i].medicaments[j].gens.length ; k++) {
                    JSONData.groups[i].medicaments[j].gens.push(fields(i,j,k))
                }
            }
        }
        let data = JSON.stringify(JSONData)
        // console.log(JSONData)
        genobiosisService.pharmacogeneticCreate({
            data
        })
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))

    }

    return (
        <div className="create-food-wrapped">
            <ModalSave open={openModal} onClose={()=>setOpenModal(false)} />
            <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                <div className='section-wrapped'>
                    <div>
                        <label htmlFor="user_id">User ID</label>
                        <input  type='text' name="user_id" placeholder="User ID" ref={register({ required: true })}
                            // defaultValue={'17'}
                        />
                    </div>
                </div>
                <div className='section-wrapped'>
                    <h4>Фармакогенетика</h4>
                    <Tabs defaultActiveKey="Антидепрессанты" id="uncontrolled-tab-example">
                    {
                        pharmacogeneticsData.map((data, idx) => {
                            return(
                                <Tab key={idx} eventKey={data.name} title={data.name}>
                                        {data.medicaments.map((val,id)=>{
                                            return(
                                                <div key={id} >
                                                    <div className="article-name"><b>{val.name}</b></div>
                                                    {val.gens.map((values, num)=>{
                                                        return(
                                                            <span key={num} className="span-padding-15 ">
                                                                <label htmlFor={idx+'-'+values.name+'-'+id}>{values.name}</label>
                                                                <input type='checkbox' name={idx+'-'+values.name+'-'+id} ref={register} />
                                                            </span>
                                                        )
                                                    })}
                                                </div>
                                            )

                                        })}

                                </Tab>
                            )
                        })
                    }
                    </Tabs>
                    </div>
                <button  type="submit">Отправить</button>
            </form>
        </div>
    )

}
export default withGenobiosisService()(CreatePharmacogenetics)