import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import {useForm} from "react-hook-form";
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import ModalSave from '../../modal-save'
import '../create-cabinet-section.sass'

const UpdateInheritedIllness = ({customerId, genobiosisService})=>{
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [inheritedIllness, setInheritedIllness] = useState('')
    const [title, setTitle] = useState('')
    const { register, handleSubmit } = useForm();



    const testFunc = ()=>{
        genobiosisService.inheritedIllnessGet(customerId)
            .then((data)=> {
                const mainData = data.data.data
                setTitle(mainData)
                // console.log(mainData)
                setInheritedIllness(
                    mainData.inheritedIllnessItems.map((val, idx)=>{
                        return(
                            <div key={idx}>
                                <div className="article-name"><b>{val.name}</b></div>
                                {
                                    val.inheritedIllnessItemInfo.map((res, id) => {
                                        return(
                                            <div key={id}>
                                                <span>
                                                    <label htmlFor={idx+'mutation'+id}>Мутация</label>
                                                    <input type='text' name={idx+'mutation'+id} placeholder="Мутация"
                                                       ref={register} defaultValue={res.mutation}/>
                                                </span>
                                                <span>
                                                    <label htmlFor={idx+'genotype'+id}>Ваш генотип</label>
                                                    <input type='text' name={idx+'genotype'+id} placeholder="Ваш генотип"
                                                       ref={register} defaultValue={res.genotype}/>
                                                </span>
                                                <span>
                                                    <label htmlFor={idx+'risk_allele'+id}>Аллель риска</label>
                                                    <input type='text' name={idx+'risk_allele'+id} placeholder="Аллель риска"
                                                       ref={register} defaultValue={res.risk_allele}/>
                                                </span>
                                                <span>
                                                    <label htmlFor={idx+'normal_allele'+id}>Аллель нормы</label>
                                                    <input type='text' name={idx+'normal_allele'+id} placeholder="Аллель нормы"
                                                           ref={register} defaultValue={res.normal_allele}/>
                                                </span>
                                            </div>
                                        )
                                    })
                                }

                                </div>
                        )
                    })
                )
                setOpen(true)

            })
            .catch(err=>console.log(err))
    }
    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: customerId+'',
            inheritedIllnessItems:[]
        }
        const fields = (num,idx)=>{
            return{
                mutation: value[`${num}mutation${idx}`],
                genotype: value[`${num}genotype${idx}`],
                risk_allele: value[`${num}risk_allele${idx}`],
                normal_allele: value[`${num}normal_allele${idx}`]

            }
        }
        const fieldsName = (idx)=> {
            return{
                name: title.inheritedIllnessItems[idx].name,
                inheritedIllnessItemInfo: []
            }
        }
        for(let i = 0; i<title.inheritedIllnessItems.length; i++){
            JSONData.inheritedIllnessItems.push(fieldsName(i))
            for(let j = 0; j<40; j++){
                if(value[`${i}mutation${j}`]){
                    JSONData.inheritedIllnessItems[i].inheritedIllnessItemInfo.push(fields(i,j))
                }

            }

        }
        let data = JSON.stringify(JSONData)
        // console.log(JSONData)
        genobiosisService.inheritedIllnessUpdate(customerId,{data})
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }

    const closeConfirm = ()=>{
        setOpenModal(false)
        setOpen(false)
    }
    return(
        <div>
            <button onClick={testFunc}>Update Innherited Illness</button>
            <ModalSave open={openModal} onClose={closeConfirm} />
            <Modal show={open} onHide={()=>{setOpen(false)}} dialogClassName="modal-90w">
                <Modal.Header closeButton/>
                <div className="create-food-wrapped">
                    <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                        <div className='section-wrapped'>
                            <h4>Наследственные заболевания</h4>
                            {inheritedIllness}
                        </div>
                        <button  type="submit">Изменить</button>
                    </form>
                </div>
            </Modal>
        </div>
    )
}
export default withGenobiosisService()(UpdateInheritedIllness)