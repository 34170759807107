import axios from 'axios'


export default class GenobiosisService {

    _apiBase = 'https://api.genobiosis.com/api/v1'

    axiosInstance = axios.create({
        baseURL: this._apiBase,
        // timeout: 5000,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('admin-token')}`,
            // 'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    adminLogin = async (data) => {
        const res = await axios.post(`${this._apiBase}/admin/auth/login`, data)
        const token = res.data.access_token
        localStorage.setItem('admin-token', token);
        return await res
    }
    consumersCreate = async (data) => {
        const res = await this.axiosInstance.post('/admin/customer', data)
        return await res
    }
    getAllConsumers = async () => {
        const res = await this.axiosInstance.get(`/admin/customers`)
        console.log(res.data.links)
        return res.data.data.map(this._transformCustomer)
        // return res.data.data
    }
    getCustomerLink = async (id)=>{
        const res = await this.axiosInstance.get(`/admin/customers?page=${id}`)
        return res.data.data.map(this._transformCustomer)

    }
    getStatus = async () => {
        const res = await this.axiosInstance.get(`/admin/statuses`)
        return res.data.data.map(this._transformStatus)
    }
    changeStatus = async (id, status) => {
        const res = await this.axiosInstance.post(`/admin/customer/${id}/status/${status}`)
        return res
    }
    deleteConsumer = async (id) => {
        const res = await this.axiosInstance.delete(`/admin/customer/${id}`)
        return res
    }
    customerUpdate = async (id, data) => {
        const res = await this.axiosInstance.put(`/admin/customer/${id}`, data)
        return await res
    }
    customerGet = async (id) => {
        const res = await this.axiosInstance.get(`/admin/customer/${id}`)
        return await res
    }
    logOut = async () => {
        const res = await this.axiosInstance.post('/admin/auth/logout')
        return await res
    }

    foodCreate = async (data) => {
        const res = await this.axiosInstance.post('/admin/food', data)
        return await res
    }
    foodUpdate = async (id, data) => {
        const res = await this.axiosInstance.put(`/admin/food/${id}`, data)
        return await res
    }
    foodGet = async (id) => {
        const res = await this.axiosInstance.get(`/admin/food/${id}`)
        return await res
    }

    skinCreate = async (data) => {
        const res = await this.axiosInstance.post('/admin/skin', data)
        return await res
    }
    skinUpdate = async (id, data) => {
        const res = await this.axiosInstance.put(`/admin/skin/${id}`, data)
        return await res
    }
    skinGet = async (id) => {
        const res = await this.axiosInstance.get(`/admin/skin/${id}`)
        return await res
    }
    illnessCreate = async (data) => {
        const res = await this.axiosInstance.post('/admin/illness', data)
        return await res
    }
    illnessUpdate = async (id, data) => {
        const res = await this.axiosInstance.put(`/admin/illness/${id}`, data)
        return await res
    }
    illnessGet = async (id) => {
        const res = await this.axiosInstance.get(`/admin/illness/${id}`)
        return await res
    }
    inheritedIllnessCreate = async (data) => {
        const res = await this.axiosInstance.post('/admin/inherited/illness', data)
        return await res
    }
    inheritedIllnessUpdate = async (id, data) => {
        const res = await this.axiosInstance.put(`/admin/inherited/illness/${id}`, data)
        return await res
    }
    inheritedIllnessGet = async (id) => {
        const res = await this.axiosInstance.get(`/admin/inherited/illness/${id}`)
        return await res
    }
    pharmacogeneticCreate = async (data) => {
        const res = await this.axiosInstance.post('/admin/pharmacogenetic', data)
        return await res
    }
    pharmacogeneticUpdate = async (id, data) => {
        const res = await this.axiosInstance.put(`/admin/pharmacogenetic/${id}`, data)
        return await res
    }
    pharmacogeneticGet = async (id) => {
        const res = await this.axiosInstance.get(`/admin/pharmacogenetic/${id}`)
        return await res
    }
    psychogeneticCreate = async (data) => {
        const res = await this.axiosInstance.post('/admin/psychogenetics', data)
        return await res
    }
    psychogeneticUpdate = async (id, data) => {
        const res = await this.axiosInstance.put(`/admin/psychogenetics/${id}`, data)
        return await res
    }
    psychogeneticGet = async (id) => {
        const res = await this.axiosInstance.get(`/admin/psychogenetics/${id}`)
        return await res
    }
    chronotypeCreate = async (data) => {
        const res = await this.axiosInstance.post('/admin/chronotype', data)
        return await res
    }
    chronotypeUpdate = async (id, data) => {
        const res = await this.axiosInstance.put(`/admin/chronotype/${id}`, data)
        return await res
    }
    chronotypeGet = async (id) => {
        const res = await this.axiosInstance.get(`/admin/chronotype/${id}`)
        return await res
    }
    physicalskillsCreate = async (data) => {
        const res = await this.axiosInstance.post('/admin/physicalskills', data)
        return await res
    }
    physicalskillsUpdate = async (id, data) => {
        const res = await this.axiosInstance.put(`/admin/physicalskills/${id}`, data)
        return await res
    }
    physicalskillsGet = async (id) => {
        const res = await this.axiosInstance.get(`/admin/physicalskills/${id}`)
        return await res
    }

    originCreate = async (data) => {
        const res = await this.axiosInstance.post('/admin/origin', data)
        return await res
    }
    originUpdate = async (id, data) => {
        const res = await this.axiosInstance.post(`/admin/origin/${id}`, data)
        return await res
    }
    originGet = async (id) => {
        const res = await this.axiosInstance.get(`/admin/origin/${id}`)
        return await res
    }
    _transformCustomer = (customer) => {
        return {
            id: customer.id,
            surname: customer.surname,
            given_name: customer.given_name,
            patronymic: customer.patronymic,
            sex: customer.sex,
            email: customer.email,
            phone: customer.phone,
            gen_id: customer.gen_id,
            birthday: customer.birthday,
            password: customer.password,
            deliver_address: customer.deliver_address,
            register_address: customer.register_address,
            // avatar: customer.avatar,
            lang: customer.lang,
            statuses: customer.statuses[0].id,
            passport_data: customer.passport_data,
            passport_type: customer.passport_type


        }
    }
    _transformStatus = (status) => {
        return {
            id: status.id,
            name: status.name,
        }
    }

}