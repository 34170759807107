import React,{ useState } from 'react'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import {useForm} from "react-hook-form";
import '../create-cabinet-section.sass'
import ModalSave from '../../modal-save'
import {psychogeneticData} from './psychogenetics-data'

const CreatePsychogenetic = ({ genobiosisService })=> {
    const [openModal, setOpenModal] = useState(false)
    const { register, handleSubmit, errors } = useForm()

    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: value.user_id,
            psychogeneticItems:[]
        }
        const fields = (idx)=>{
            return{
                gen: psychogeneticData[idx].gen,
                product_gen: psychogeneticData[idx].product_gen,
                polymorphism: value[`${idx}polymorphism`],
                result: value[`${idx}result`],
                signs: value[`${idx}signs`]

            }
        }

        for(let i = 0; i<psychogeneticData.length; i++){
            JSONData.psychogeneticItems.push(fields(i))
        }
        let data = JSON.stringify(JSONData)
        // console.log(JSONData)
        genobiosisService.psychogeneticCreate({
            data
        })
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }
    return(
        <div className="create-food-wrapped">
            <ModalSave open={openModal} onClose={()=>setOpenModal(false)} />
            <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                <div className='section-wrapped'>
                    <div>
                        <label htmlFor="user_id">User ID</label>
                        <input  type='text' name="user_id" placeholder="User ID" ref={register({ required: true })}
                            // defaultValue={'17'}
                        />
                    </div>
                </div>
                <div className='section-wrapped'>
                    <h4>Психогенетика</h4>
                    {psychogeneticData.map((data, idx) => {
                        return(
                            <div key={idx}>
                                <div><b>{data.gen}</b></div>
                                <span>
                                    <label htmlFor={idx+'polymorphism'}>Полиморфизм</label>
                                    <input type='text' name={idx+'polymorphism'} placeholder="Полиморфизм"
                                       ref={register({required: true})}
                                       defaultValue={idx+'polymorphism'}
                                    />
                                    {errors[idx+'polymorphism'] && <p className="error-text">{'*required'}</p>}
                                </span>
                                <span>
                                    <label htmlFor={idx+'result'}>Результат</label>
                                    <input type='text' name={idx+'result'} placeholder="Результат"
                                       ref={register({required: true})}
                                       defaultValue={'A'+idx}
                                    />
                                    {errors[idx+'result'] && <p className="error-text">{'*required'}</p>}
                                </span>
                                <span>
                                    <label htmlFor={idx+'signs'}>Признаки</label>
                                    <input type='text' name={idx+'signs'} placeholder="Признаки"
                                           ref={register({required: true})}
                                        defaultValue={'bb'+idx}
                                    />
                                    {errors[idx+'signs'] && <p className="error-text">{'*required'}</p>}
                                </span>
                            </div>
                        )
                    })}
                </div>
                <button  type="submit">Отправить</button>
            </form>
        </div>
    )
}
export default withGenobiosisService()(CreatePsychogenetic)