import React from 'react'
import {Route, Switch, withRouter, NavLink} from 'react-router-dom';
import {logout} from "../../utils/utils";
import withGenobiosisService from "../hoc/with-genobiosis-service";
import compose from "../../utils/compose";
import CreateCustomer from './create-customer'
import CustomersList from './customers-list'
import GetAllCustomers from './update-customers/get-all-customers'
import CreateCabinetSection from "./create-cabinet-section/create-cabinet-section"

const AdminPage = ({history, genobiosisService})=>{

    const logOut = () => {
        genobiosisService.logOut()
        // .then((data)=> console.log(data))
        logout()
        history.push(`/`)


    }
    return(
    <div className="container-fluid main-wrapp">
        <div className="row">
            <nav className="col-md-2">
                <div className="sidebar-sticky">
                    <div className="nav flex-column">
                        <div className="d-flex flex-column p-3 align-items-start nav-item">
                            <NavLink to="/admin" activeStyle={{color: "red"}} exact >Создать клиента</NavLink>
                            <NavLink to="/admin/create-lk" activeStyle={{color: "red"}} exact>Заполнить информацию ЛК</NavLink>
                            <NavLink to="/admin/update-lk" activeStyle={{color: "red"}} exact>Изменить информацию ЛК</NavLink>
                            <NavLink to="/admin/customers" activeStyle={{color: "red"}} exact>Список клиентов</NavLink>
                        </div>
                    </div>
                </div>
                <button onClick={logOut}>Выход</button>
            </nav>
            <main role="main" className="col-md-10">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                    <Switch>
                        <Route
                            path="/admin"
                            component={CreateCustomer}
                            exact
                            />

                        <Route
                            path="/admin/customers"
                            component={CustomersList}

                        />
                        <Route
                            path="/admin/create-lk"
                            component={CreateCabinetSection}

                        />
                        <Route
                            path="/admin/update-lk"
                            component={GetAllCustomers}

                        />
                    </Switch>
                </div>

            </main>
        </div>
    </div>
    )
}
export default compose(withGenobiosisService())(withRouter(AdminPage))