import React from 'react';

const {
    Provider: GenobiosisServiceProvider,
    Consumer: GenobiosisServiceConsumer
} = React.createContext();

export {
    GenobiosisServiceProvider,
    GenobiosisServiceConsumer
};