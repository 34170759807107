import React,{Component} from 'react'
import { connect } from 'react-redux';
import withGenobiosisService from "../hoc/with-genobiosis-service";
import { fetchStatus, statusError } from '../../actions'
import  compose  from '../../utils/compose';
import ErrorIndicator from '../error-indicator';
import Spinner from '../Spinner'
class GetAllStatus extends Component {
    state = {
        customers: ''
    }
    componentDidMount(){
        this.props.fetchStatus()

    }
    changeFn = (e)=>{
        const { genobiosisService, customerId } = this.props
        const currentOption = e.target.value
        // console.log(Id)
        // console.log(currentOption)
        genobiosisService.changeStatus(customerId,currentOption)
            .catch((err) => statusError(err));
    }
    render(){
        const { status, loadingS, errorS } = this.props;

        if (loadingS) {
            return <Spinner />;
        }

        if (errorS) {
            return <ErrorIndicator />;
        }

        return(
            <div>
                <select style={{width: '50px'}} onChange={this.changeFn}>
                    {
                        status.map((st) => {
                            return (
                                <option key={st.id} value={st.id} >
                                    {st.id}
                                </option>
                            )
                        })
                    }
                </select>
            </div>
        )
    }
}
const mapStateToProps = ({ status, loadingS, errorS }) => {
    return { status, loadingS, errorS };
};

const mapDispatchToProps = (dispatch, { genobiosisService }) => {
    return {
        fetchStatus: fetchStatus(genobiosisService, dispatch)
    };
};


export default compose(withGenobiosisService(), connect(mapStateToProps, mapDispatchToProps)
)(GetAllStatus);