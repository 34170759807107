import React,{ useState } from 'react'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import {useForm} from "react-hook-form";
import '../create-cabinet-section.sass'
import ModalSave from '../../modal-save'


const CreateSkin = ({genobiosisService})=>{
    const [openModal, setOpenModal] = useState(false)
    const fieldData = [
        {name: 'Склонность к образованию морщин'},
        {name: 'Потребность в фолиевой кислоте'},
        {name: 'Склонность к потере влаги'},
        {name: 'Потребность в витамине Е'},
        {name: 'Активность антиоксидантной защиты'},
        {name: 'Потребность в витамине D'},
        {name: 'Склонность к повышенному риску развития меланомы'},
        {name: 'Чувствительность к токсинам'},
        {name: 'Склонность к потере волос'},
        {name: 'Чувствительность к курению'},
        {name: 'Склонность к образованию целлюлита'},
        {name: 'Чувствительность к кофеину'},
        {name: 'Склонность к варикозному расширению вен'},
        {name: 'Чувствительность к УФ-повреждению'},
        {name: 'Потребность в Омега-3'}
    ]

    const { register, handleSubmit, errors } = useForm()
    const onSubmit = (value, event) => {
        event.preventDefault()

        let JSONData = {
            user_id: value.user_id,
            recommendations:[

            ]
        }
        const fields = (idx)=>{
            return{
                features_level: value[`features_level${idx}`],
                system: value[`system${idx}`],
                procedural: value[`procedural${idx}`],
                topical: value[`topical${idx}`]
            }
        }
        for(let i = 0; i<=fieldData.length; i++){
            if(value[`features_level${i}`]){
                JSONData.recommendations.push(fields(i))
            }

        }

        let data = JSON.stringify(JSONData)
        console.log(JSONData)
        genobiosisService.skinCreate({
            data
        })
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }


    return (
        <div className="create-food-wrapped">
            <ModalSave open={openModal} onClose={()=>setOpenModal(false)} />
            <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                <div className='section-wrapped'>
                    <div>
                        <label htmlFor="user_id">User ID</label>
                        <input  type='text' name="user_id" placeholder="User ID" ref={register({ required: true })}  />
                    </div>
                </div>
                {errors.user_id &&  <p className="error-text">{'*required'}</p>}
                <div className='section-wrapped'>
                    <h4>Паспорт кожи</h4>
                    {fieldData.map((data, idx)=>{
                        return(
                            <div key={idx}>
                                <div className="article-name"><b>{data.name}</b></div>
                                <div>
                                    <label htmlFor={'features_level'+idx}>%</label>
                                    <input type='number'  step="any" name={'features_level'+idx} placeholder="%"
                                           ref={register({required: true})}/>
                                </div>
                                {errors['features_level'+idx] && <p className="error-text">{'*required'}</p>}
                                <div><b><i>Pекомендации</i></b></div>
                                <div>
                                    <label htmlFor={'system'+idx}>Системные:</label>
                                    <input type='text' name={'system'+idx} placeholder="Системные"
                                           ref={register({required: true})}/>
                                </div>
                                {errors['system'+idx] && <p className="error-text">{'*required'}</p>}
                                <div>
                                    <label htmlFor={'procedural'+idx}>Процедурные:</label>
                                    <input type='text' name={'procedural'+idx} placeholder="Процедурные"
                                           ref={register({required: true})}/>
                                </div>
                                {errors['procedural'+idx] && <p className="error-text">{'*required'}</p>}
                                <div>
                                    <label htmlFor={'topical'+idx}>Топические:</label>
                                    <input type='text' name={'topical'+idx} placeholder="Топические"
                                           ref={register({required: true})}/>
                                </div>
                                {errors['topical'+idx] && <p className="error-text">{'*required'}</p>}
                            </div>
                        )
                    })}
                </div>
                <button  type="submit">Отправить</button>
            </form>
        </div>
    )
}
export default withGenobiosisService()(CreateSkin)