import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import {useForm} from "react-hook-form";
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import ModalSave from '../../modal-save'
import '../create-cabinet-section.sass'

const UpdateSkin = ({customerId, genobiosisService})=>{
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [recomendations, setRecomendations] = useState('')
    const fieldData = [
        {name: 'Склонность к образованию морщин'},
        {name: 'Потребность в фолиевой кислоте'},
        {name: 'Склонность к потере влаги'},
        {name: 'Потребность в витамине Е'},
        {name: 'Активность антиоксидантной защиты'},
        {name: 'Потребность в витамине D'},
        {name: 'Склонность к повышенному риску развития меланомы'},
        {name: 'Чувствительность к токсинам'},
        {name: 'Склонность к потере волос'},
        {name: 'Чувствительность к курению'},
        {name: 'Склонность к образованию целлюлита'},
        {name: 'Чувствительность к кофеину'},
        {name: 'Склонность к варикозному расширению вен'},
        {name: 'Чувствительность к УФ-повреждению'},
        {name: 'Потребность в Омега-3'}
    ]
    const testFunc = ()=>{
        genobiosisService.skinGet(customerId)
            .then((data)=> {

                const mainData = data.data.data
                console.log(mainData)
                setRecomendations(
                    mainData.recommendations.map((val,idx)=>{
                        return(
                            <div key={idx}>
                                <div className="article-name"><b>{fieldData[idx].name}</b></div>
                                <div>
                                    <label htmlFor={'features_level'+idx}>%</label>
                                    <input type='number'  step="any" name={'features_level'+idx} placeholder="%"
                                           ref={register}  defaultValue={val.features_level}/>
                                </div>
                                <div><b><i>Pекомендации</i></b></div>
                                <div>
                                    <label htmlFor={'system'+idx}>Системные:</label>
                                    <input type='text' name={'system'+idx} placeholder="Системные"
                                           ref={register({required: true})} defaultValue={val.system}/>
                                </div>
                                <div>
                                    <label htmlFor={'procedural'+idx}>Процедурные:</label>
                                    <input type='text' name={'procedural'+idx} placeholder="Процедурные"
                                           ref={register({required: true})} defaultValue={val.procedural}/>
                                </div>
                                <div>
                                    <label htmlFor={'topical'+idx}>Топические:</label>
                                    <input type='text' name={'topical'+idx} placeholder="Топические"
                                           ref={register({required: true})} defaultValue={val.topical}/>
                                </div>
                            </div>
                        )

                    })
                )
                setOpen(true)
            })
            .catch((err)=>{console.log(err)})

    }

    const { register, handleSubmit } = useForm();

    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: customerId+'',
            recommendations:[

            ]
        }
        const fields = (idx)=>{
            return{
                features_level: value[`features_level${idx}`],
                system: value[`system${idx}`],
                procedural: value[`procedural${idx}`],
                topical: value[`topical${idx}`]
            }
        }
        for(let i = 0; i<=fieldData.length; i++){
            if(value[`features_level${i}`]){
                JSONData.recommendations.push(fields(i))
            }

        }

        let data = JSON.stringify(JSONData)

        genobiosisService.skinUpdate(customerId,{data})
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }

    // const testFunc = ()=>{
    //     setOpen(true)
    // }
    const closeConfirm = ()=>{
        setOpenModal(false)
        setOpen(false)
    }
    return(
        <div>
            <button onClick={testFunc}>Update Skin</button>
            <ModalSave open={openModal} onClose={closeConfirm} />
            <Modal show={open} onHide={()=>{setOpen(false)}} dialogClassName="modal-90w">
                <Modal.Header closeButton/>
                <div className="create-food-wrapped">
                    <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                        <div className='section-wrapped'>
                            <h4>Паспорт кожи</h4>
                            {recomendations}
                        </div>
                        <button  type="submit">Изменить</button>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default withGenobiosisService()(UpdateSkin)