import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import './modal-confirm/modal-confirm.sass'


const ModalConfirmDelete =({open, onClose,onConfirm})=>{
    return (
        <Modal show={open} onHide={onClose} centered size="xl">
            <div className="wrapped-modal-confirm d-flex">
                <Modal.Header closeButton/>
                <p>Вы действительно хотите удалить пользователя?</p>
                <Modal.Footer>
                    <Button variant="info" onClick={onClose} >Отмена</Button>
                    <Button variant="danger" onClick={onConfirm} >Удалить</Button>
                </Modal.Footer>
            </div>
        </Modal>
    )
}
export default ModalConfirmDelete