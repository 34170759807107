import React from 'react';
import { GenobiosisServiceConsumer } from "../genobiosis-service-context";

const withGenobiosisService = () => (Wrapped) => {

    return (props) => {
        return (
            <GenobiosisServiceConsumer>
                {
                    (genobiosisService) => {
                        return (<Wrapped {...props}
                                         genobiosisService={genobiosisService}/>);
                    }
                }
            </GenobiosisServiceConsumer>
        );
    }
};

export default withGenobiosisService;