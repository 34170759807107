const gens = [
    {name: 'CYP1A2'},
    {name: 'CYP2B6'},
    {name: 'CYP2C19'},
    {name: 'CYP2C9'},
    {name: 'CYP3A4'},
    {name: 'CYP2D6'},
    {name: 'CYP3A5'},
    {name: 'UGT2B15'},
    {name: 'VKORC1 Rs9923231'}
]
export const pharmacogeneticsData = [
    {
        name: "Антидепрессанты",
        medicaments: [
            {
                name: "Десвенлафаксин (Элифор)",
                gens: gens
            },
            {
                name: "Циталопрам (Ципрамил)",
                gens: gens
            },
            {
                name: "Эсциталопрам (Эсциталопрам-Тева)",
                gens: gens
            },
            {
                name: "Флуоксетин (Прозак, Продеп, Флуксен)",
                gens: gens
            },
            {
                name: "Селегилин (Эльдеприл, Селегилин Гексал)",
                gens: gens
            },
            {
                name: "Сертралин (Залокс, Золофт, Стимулотон)",
                gens: gens
            },
            {
                name: "Тразодон (Триттико)",
                gens: gens
            },
            {
                name: "Венлафаксин (Велаксин, Венлаксор)",
                gens: gens
            },
            {
                name: "Амитриптиллин",
                gens: gens
            },
            {
                name: "Бупропион (Велбутрин SR)",
                gens: gens
            },
            {
                name: "Кломипрамин (Анафранил, Клофранил)",
                gens: gens
            },
            {
                name: "Доксепин",
                gens: gens
            },
            {
                name: "Дулоксетин (Депулокса, Дефаксто, Дулокем)",
                gens: gens
            },
            {
                name: "Флувоксамин (Депривокс, Феварин, Флувоксамин Сандоз)",
                gens: gens
            },
            {
                name: "Имипрамин (Мелипрамин)",
                gens: gens
            },
            {
                name: "Миртазапин (Еспритал, Миразеп, Мирзатен)",
                gens: gens
            },
            {
                name: "Пароксетин (Клоксет, Люксотил)",
                gens: gens
            },
            {
                name: "Вортиоксетин (Бринтелликс)",
                gens: gens
            }

        ]
    },
    {
        name: "Анксиолитики/гипнотики",
        medicaments: [
            {
                name: "Алпразолам (Алпразолам-ЗН, Золомакс)",
                gens: gens
            },
            {
                name: "Буспирон (Буспирон Сандоз)",
                gens: gens
            },
            {
                name: "Клоназепам",
                gens: gens
            },
            {
                name: "Золпидем (Ивадал, Гипноген)",
                gens: gens
            },
            {
                name: "Хлордиазепоксид (Элениум)",
                gens: gens
            },
            {
                name: "Диазепам (Диазепам, Диазепекс, Реланиум)",
                gens: gens
            },
            {
                name: "Лоразепам",
                gens: gens
            },
            {
                name: "Оксазепам (Нозепам, Тазепам)",
                gens: gens
            },
            {
                name: "Пропранолол (Анаприлин-Здоровье, Пранолол)",
                gens: gens
            }

        ]
    },
    {
        name: "Антипсихотики",
        medicaments: [
            {
                name: "Клозапин (Азалептин, Азапин)",
                gens: gens
            },
            {
                name: "Флуфеназин (Модитен депо)",
                gens: gens
            },
            {
                name: "Флуфеназин (Модитен депо)",
                gens: gens
            },
            {
                name: "Галоперидол",
                gens: gens
            },
            {
                name: "Оланзапин (Золафрен, Зипрекса)",
                gens: gens
            },
            {
                name: "Кветиапин (Кветиксол)",
                gens: gens
            },
            {
                name: "Арипипразол (Арилентал, Арипрадекс)",
                gens: gens
            },
            {
                name: "Хлорпромазин (Аминазин)",
                gens: gens
            },
            {
                name: "Рисперидон (Рисперон, Риспаксол)",
                gens: gens
            },

            {
                name: "Тиоридазин (Ридазин, Сонапакс)",
                gens: gens
            }
        ]
    },
    {
        name: "Стабилизаторы настроения",
        medicaments: [
            {
                name: "Вальпроевая кислота (Вальпроат 300-Тева, Конвулекс)",
                gens: gens
            },
            {
                name: "Карбамазепин",
                gens: gens
            }
        ]
    },
    {
        name: "Противосвертывающие и антитромботические средства",
        medicaments: [
            {
                name: "Варфарин",
                gens: gens
            },
            {
                name: "Клопидогрель",
                gens: gens
            }
        ]
    },
    {
        name: "Иммуносупрессоры",
        medicaments: [
            {
                name: "Циклоспорин (Tacrolimus)",
                gens: gens
            }
        ]
    },
    {
        name: "Противоопухолевые средства",
        medicaments: [
            {
                name: "Тамоксифен",
                gens: gens
            }
        ]

    }


]
