import React from 'react'
import PrivateRoute  from '../../utils/private-route'
import AdminPage from '../admin-page/admin-page'
import 'bootstrap/dist/css/bootstrap.min.css'
import LoginPage from '../admin-page/login-page'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';


const App = ()=>{
    return(
        <Switch>
            <Route path="/" component={LoginPage} exact/>
            <PrivateRoute component={AdminPage} path="/admin" />
        </Switch>
    )
}
export default App