import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import {useForm} from "react-hook-form";
import withGenobiosisService from "../hoc/with-genobiosis-service";
import ModalSave from './modal-save'
import './create-cabinet-section/create-cabinet-section.sass'

const UpdateCustomer = ({customerId, genobiosisService})=> {
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [customerInfo, setCustomerInfo] = useState('')
    const {register, handleSubmit} = useForm();
    const dataSex =[
        {sex:'man'},
        {sex:'woman'}
    ]
    const typePassport =[
        {type:'Gen'},
        {type:'Gen++'},
        {type:'Genom'}
    ]
    const dataLang =[
        {lang:'ro'},
        {lang:'ru'},
        {lang:'en'}
    ]
    const testFunc = ()=> {
        genobiosisService.customerGet(customerId)
            .then((data)=>{
                const mainData = data.data.data
                // console.log(mainData)
                setCustomerInfo(
                    <div>
                        <div>
                            <label htmlFor="surname">Фамилия</label>
                            <input  type='text' name="surname" placeholder='Фамилия' ref={register} defaultValue={mainData.surname}/>
                        </div>
                        <div>
                            <label htmlFor="given_name">Имя</label>
                            <input  type='text' name="given_name" placeholder="Имя" ref={register} defaultValue={mainData.given_name} />
                        </div>
                        <div>
                            <label htmlFor="patronymic">Отчество</label>
                            <input  type='text' name="patronymic" placeholder="Отчество" ref={register} defaultValue={mainData.patronymic} />
                        </div>
                        <div>
                            <label htmlFor="sex">Пол</label>
                            <select ref={register} name="sex">
                                {dataSex.map((data, idx)=>{
                                    return <option key={idx} selected={data.sex===mainData.sex?true:false} value={data.sex}>{data.sex}</option>
                                })}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="passport_type">Тип паспорта</label>
                            <select ref={register} name="passport_type">
                                {typePassport.map((data, idx)=>{
                                    return <option key={idx} selected={data.type===mainData.passport_type?true:false} value={data.type}>{data.type}</option>
                                })}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="phone">Телефон</label>
                            <input type='phone' name="phone" placeholder="+ХХХХХХХХХХХХ" ref={register} defaultValue={mainData.phone} />
                        </div>
                        <div>
                            <label htmlFor="birthday">Дата рождения</label>
                            <input type='text' name="birthday" placeholder="YYYY-MM-DD" ref={register} defaultValue={mainData.birthday} />
                        </div>
                        <div>
                            <label htmlFor="deliver_address">Адрес доставки паспорта</label>
                            <input type='text' name="deliver_address" placeholder="Адрес доставки паспорта" ref={register} defaultValue={mainData.deliver_address} />
                        </div>
                        <div>
                            <label htmlFor="register_address">Адрес регистрации</label>
                            <input type='text' name="register_address" placeholder="Адрес регистрации" ref={register} defaultValue={mainData.register_address} />
                        </div>
                        <div>
                            <label htmlFor="passport_data">Паспортные данные</label>
                            <input type='text' name="passport_data" placeholder="Паспортные данные" ref={register} defaultValue={mainData.passport_data} />
                        </div>
                        <div>
                            <label htmlFor="email">Email</label>
                            <input type='email' name="email" placeholder="email" ref={register} defaultValue={mainData.email} />
                        </div>
                        <div>
                            <label htmlFor="lang">Язик</label>
                            <select ref={register} name="passport_type">
                                {dataLang.map((data, idx)=>{
                                    return <option key={idx} selected={data.lang===mainData.lang?true:false} value={data.lang}>{data.lang}</option>
                                })}
                            </select>
                        </div>
                    </div>
                )
                setOpen(true)
            })
            .catch(err=>console.log(err))
    }
    const onSubmit = (value, event) => {
        event.preventDefault()
        // console.log(value)
        genobiosisService.customerUpdate(customerId,{
            user_id: customerId,
            surname: value.surname,
            given_name: value.given_name,
            patronymic: value.patronymic,
            sex: value.sex,
            phone: value.phone,
            email: value.email,
            birthday: value.birthday,
            deliver_address: value.deliver_address,
            register_address: value.register_address,
            passport_data: value.passport_data,
            password: value.password,
            passport_type: value.passport_type,
            lang: value.lang
        })
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }
    const closeConfirm = ()=>{
        setOpenModal(false)
        setOpen(false)
    }
    return (
        <div>
            <button onClick={testFunc}>Update Customer</button>
            <ModalSave open={openModal} onClose={closeConfirm} />
            <Modal show={open} onHide={()=>{setOpen(false)}} dialogClassName="modal-90w">
                <Modal.Header closeButton/>
                <div className="create-customer-wrapped">
                    <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                        <div className='section-wrapped'>
                            <h4>Изменить информацию клиента</h4>
                            {customerInfo}
                        </div>
                        <button  type="submit">Изменить</button>
                    </form>
                </div>
            </Modal>
        </div>

    )
}
export default withGenobiosisService()(UpdateCustomer)