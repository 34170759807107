import React, {useState, useEffect} from 'react';
import withGenobiosisService from "../hoc/with-genobiosis-service";
import './admin-page.sass'
import './modal-confirm/modal-confirm.sass'
import compose from "../../utils/compose";
import {useForm} from "react-hook-form";

const LoginPage =({genobiosisService})=> {

    const { register, handleSubmit, errors } = useForm()
    const onSubmit = (value, event) => {
        event.preventDefault()
        genobiosisService.adminLogin({
            email: value.input_email,
            password: value.input_password
        })
            .then(()=>{
                // history.push(`/${language}/admin`)
                window.location.href = `${window.location.origin}/admin`
            })
            .catch(err => console.log(err))

    }


        return (
            <section>
                <div className="d-flex login-bg" style={{'background': `linear-gradient(173.73deg, #FFFFFF 14.26%, #EEF5FF 41.04%, #FFFFFF 87.85%, #CFDEF3 166.4%)`}}>
                    <div className="menu-form">
                        <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                            <h5>Вход в личный кабинет администратора</h5>
                            <div>
                                <label htmlFor="input_email" className="sr-only">Email</label>
                                <input  type='email' name="input_email" className="form-control" placeholder='Email' ref={register({ required: true })} />
                            </div>
                            {errors.input_email &&  <p className="error-text">{'*required'}</p>}
                            <div>
                                <label htmlFor="input_password" className="sr-only">Пароль</label>
                                <input  type='password' className="form-control" name="input_password" placeholder="Введите ваш пароль" ref={register({ required: true })} />
                            </div>
                            {errors.input_password && <p className="error-text">{'*required'}</p>}

                            <button className="button" type="submit">Войти</button>
                        </form>
                    </div>
                </div>
            </section>
        )
    }


export default compose(withGenobiosisService())(LoginPage)
