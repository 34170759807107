import React,{useState, useEffect} from 'react'
import withGenobiosisService from "../../hoc/with-genobiosis-service";
import GetCustomer from "./get_customer";


const GetAllCustomers =({genobiosisService})=> {
    const [customers, setCustomers]=useState([])
    const [page, setPage]=useState(1)
    useEffect(()=>{
        changePage(page)
    },[])
    const changePage =(num)=>{
        genobiosisService.getCustomerLink(num)
            .then((data) => {
                console.log(data)
                setCustomers(data)
            })
            .catch((err) => console.log(err));
    }
    return(
            <section>
                <h2>Изменить информацию личного кабинета</h2>
                <div style={{overflow: 'auto' }} className='table-list table-responsive-lg'>
                    <table className="table table-striped table-sm table-customer">
                        <thead>
                        <tr>
                            <th>User ID</th>
                            <th>Фамилия</th>
                            <th>Имя</th>
                            <th>Обновить статус готовности</th>
                            <th>Редактировать клиента</th>
                            <th>Удалить</th>
                            <th>Редактировать ЛК(Питание)</th>
                            <th>Редактировать ЛК(Паспорт кожи)</th>
                            <th>Редактировать ЛК(Риски болезней)</th>
                            <th>Редактировать ЛК(Наследственные болезни)</th>
                            <th>Редактировать ЛК(Фармакогенетика)</th>
                            <th>Редактировать ЛК(Психогенетика)</th>
                            <th>Редактировать ЛК(Хронотип)</th>
                            <th>Редактировать ЛК(Физические способности)</th>
                            <th>Редактировать ЛК(Происхождение)</th>
                        </tr>
                        </thead>
                        <tbody>
                            {customers.map((customer) => {
                                return <GetCustomer key={customer.id} customer={customer}/>
                            })}
                        </tbody>
                    </table>
                </div>
                <button onClick={()=>changePage(1)}>1</button>
                <button onClick={()=>changePage(2)}>2</button>
            </section>
    )
}
export default withGenobiosisService()(GetAllCustomers);