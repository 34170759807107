import React,{ useState } from 'react'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import {useForm} from "react-hook-form";
import '../create-cabinet-section.sass'
import ModalSave from '../../modal-save'

const CreateOrigin= ({ genobiosisService })=> {
    const [openModal, setOpenModal] = useState(false)
    const [fields, setFields] = useState([{ value: null }]);
    const {register, handleSubmit, errors} = useForm()
    function handleAdd() {
        const values = [...fields];
        values.push({ value: null });
        setFields(values);
    }
    function handleRemove(i) {
        const values = [...fields];
        values.splice(i, 1);
        setFields(values);
    }
    const haplogroup = [
        {title: 'Материнская гаплогруппа'},
        {title: 'Отцовская гаплогруппа'}
    ]
    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: value.user_id,
            ancestor: value.ancestor,
            ancestor_description: value.ancestor_description,
            ethnicComposition: [],
            maternalHaplogroup:
                {
                    result: value['haplogroup_result0'],
                    origin_description: value['haplogroup_description0'],
                    ancestors:[]
                },
            fatherHaplogroup:
                {
                    result: value['haplogroup_result1'],
                    origin_description: value['haplogroup_description1'],
                    ancestors:[]
                }
        }

        const fieldsItems = (idx)=>{
            return{
                value: value['ethnic'+idx],
                percent: value['percent'+idx],
            }
        }
        const fieldsItemsHaplogroup = (num,idx)=>{
            return{
                common_ancestor: value[num+'common_ancestor'+idx],
                royal_ancestor: value[num+'royal_ancestor'+idx],
                result: value[num+'result_ancestor'+idx],
            }
        }
        for(let i = 0; i<fields.length; i++){
            JSONData.ethnicComposition.push(fieldsItems(i))
        }

        // for(let i = 0; i<haplogroup.length; i++){
            for(let j = 0; j<20; j++) {
            if(value[0+'common_ancestor'+j]){
                JSONData.maternalHaplogroup.ancestors.push(fieldsItemsHaplogroup(0,j))
            }
            if(value[1+'common_ancestor'+j]){
                JSONData.fatherHaplogroup.ancestors.push(fieldsItemsHaplogroup(1,j))
            }
        }
        let data = JSON.stringify(JSONData)
        // console.log(value)
        // dict of all elements
        const formData = new FormData()

        formData.append('data', data)
        formData.append('motherImage', value.load_map0[0])
        formData.append('fatherImage', value.load_map1[0])
        // console.log(value.load_map0[0])
        // console.log(value.load_map1[0])
        genobiosisService.originCreate(formData)
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }

    const ResultHaplogroup = (index) =>{
        const [fieldHaplogroup, setFieldHaplogroup] = useState([{ value: null }]);
        function handleAdd() {
            const values = [...fieldHaplogroup];
            values.push({ value: null });
            setFieldHaplogroup(values);
        }
        function handleRemove(i) {
            const values = [...fieldHaplogroup];
            values.splice(i, 1);
            setFieldHaplogroup(values);
        }
        return(
            <React.Fragment>
                <div>Добавить предка</div>
                <span>
                    <button type="button" onClick={() => handleAdd()}>+</button>
                </span>
                {fieldHaplogroup.map((field, idx) => {
                    return (
                        <div key={`${field}-${idx}`}>
                            <span>
                                <label htmlFor={index+'common_ancestor'+idx}>Общий предок</label>
                                <input type='text' name={index+'common_ancestor'+idx} placeholder="Общий предок"
                                       ref={register({required: true})}
                                       defaultValue={'R-M269'+idx}
                                />
                                {errors[index+'common_ancestor'+idx] && <p className="error-text">{'*required'}</p>}
                            </span>
                            <span>
                                <label htmlFor={index+'royal_ancestor'+idx}>Королевский предок</label>
                                <input type='text' name={index+'royal_ancestor'+idx} placeholder="Королевский предок"
                                       ref={register({required: true})}
                                       defaultValue={'Мария Антуанетта'}
                                />
                                {errors[index+'royal_ancestor'+idx] && <p className="error-text">{'*required'}</p>}
                            </span>
                            <span>
                                <label htmlFor={index+'result_ancestor'+idx}>Результат</label>
                                <input type='text' name={index+'result_ancestor'+idx} placeholder="Результат"
                                       ref={register({required: true})}
                                       defaultValue={'Евреи'+idx}
                                />
                                {errors[index+'result_ancestor'+idx] && <p className="error-text">{'*required'}</p>}
                            </span>
                            <span>
                                <button type="button" onClick={() => handleRemove(idx)}>X</button>
                            </span>

                        </div>
                    )

                })
                }

            </React.Fragment>

        )
    }


    return (
        <div className="create-food-wrapped">
            <ModalSave open={openModal} onClose={() => setOpenModal(false)}/>
            <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                <div className='section-wrapped'>
                    <div>
                        <label htmlFor="user_id">User ID</label>
                        <input type='text' name="user_id" placeholder="User ID" ref={register({required: true})}
                            // defaultValue={'17'}
                        />
                    </div>
                </div>
                <div className='section-wrapped'>
                    <h4>Происхождение</h4>
                    <div>
                        <div><b>Ваш предок</b></div>
                        <div>
                            <label htmlFor={'ancestor_description'}>Ваш результат</label>
                            <input type='text' name={'ancestor_description'} placeholder="Ваш результат"
                                   ref={register({required: true})}
                                // defaultValue={'A'}
                            />
                            {errors['ancestor_description'] && <p className="error-text">{'*required'}</p>}
                        </div>
                        <div>
                            <label htmlFor={'ancestor'}>Предок</label>
                            <select name="ancestor" ref={register}>
                                <option value="Неандерталец">Неандерталец</option>
                            </select>
                        </div>

                    </div>
                    <div>
                        <div><b>Этнический состав</b></div>
                        <span>
                            <button type="button" onClick={() => handleAdd()}>+</button>
                        </span>
                        {fields.map((field, idx) => {
                            return (
                                <div key={`${field}-${idx}`}>
                                    <span>
                                        <label htmlFor={'ethnic'+idx}>Этнический состав</label>
                                        <input type='text' name={'ethnic'+idx} placeholder="Этнический состав"
                                               ref={register({required: true})}
                                               defaultValue={'Евреи'+idx}
                                        />
                                        {errors['ethnic'+idx] && <p className="error-text">{'*required'}</p>}
                                    </span>
                                    <span>
                                        <label htmlFor={'percent'+idx}>%</label>
                                        <input type='number'  step="any" name={'percent'+idx} placeholder="%"
                                               ref={register({ required: true })}
                                               defaultValue={'6'+idx}
                                        />
                                                {errors['percent'+idx] && <p className="error-text">{'*required'}</p>}
                                    </span>

                                    <span>
                                        <button type="button" onClick={() => handleRemove(idx)}>X</button>
                                    </span>
                                </div>
                                )

                            })
                        }
                    </div>
                    {
                        haplogroup.map((item, idx)=>{
                            return(
                                <div key={idx}>
                                    <div><b>{item.title}</b></div>
                                    <div>
                                        <label htmlFor={'haplogroup_result'+idx}>Результат</label>
                                        <input type='text' name={'haplogroup_result'+idx} placeholder="Результат"
                                               ref={register({required: true})}
                                            // defaultValue={'A'}
                                        />
                                        {errors['haplogroup_result'+idx] && <p className="error-text">{'*required'}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor={'haplogroup_description'+idx}>Описание</label>
                                        <input type='text' name={'haplogroup_description'+idx} placeholder="Описание"
                                               ref={register({required: true})}
                                            // defaultValue={'A'}
                                        />
                                        {errors['haplogroup_description'+idx] && <p className="error-text">{'*required'}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor={'load_map'+idx}>Загрузить карту</label>
                                        <input type="file" name={'load_map'+idx} accept="image/jpg, image/jpeg, image/png" ref={register} />
                                    </div>
                                    <div>
                                        {ResultHaplogroup(idx)}
                                    </div>
                                </div>

                            )

                        })
                    }
                </div>
                <button type="submit">Отправить</button>
            </form>
        </div>
    )
}
export default withGenobiosisService()(CreateOrigin)