import React  from 'react'
import { Tabs, Tab }from 'react-bootstrap'
import './create-cabinet-section.sass'
import CreateFood from './food/create-food'
import CreateSkin from './skin/create-skin'
import CreateIllness from "./illness/create-illness";
import CreateInheritedIllness from "./inherited-illness/create-inherited-illness";
import CreatePharmacogenetics from "./pharmacogenetics/create-pharmacogenetics";
import CreatePsychogenetic from "./psychogenetics/create-psychogenetics";
import CreateChronotypes from './chronotypes/create-chronotypes'
import CreatePhysicalSkills from './physical-skills/create-physical-skills'
import CreateOrigin from './origin/create-origin'

const CreateCabinetSection = () =>{

    return(
        <section>
            <h2>Заполнить информацию личного кабинета </h2>
            <Tabs defaultActiveKey="food" id="uncontrolled-tab-example">
                <Tab eventKey="food" title="Питание">
                    <CreateFood/>
                </Tab>
                <Tab eventKey="skin" title="Паспорт Кожи">
                    <CreateSkin/>
                </Tab>
                <Tab eventKey="illness" title="Риски болезней">
                    <CreateIllness/>
                </Tab>
                <Tab eventKey="inherited-illness" title="Наследственные болезни">
                    <CreateInheritedIllness/>
                </Tab>
                <Tab eventKey="pharmacogenetics" title="Фармакогенетика">
                    <CreatePharmacogenetics/>
                </Tab>
                <Tab eventKey="psychogenetics" title="Психогенетика">
                    <CreatePsychogenetic/>
                </Tab>
                <Tab eventKey="chronotypes" title="Хронотип">
                    <CreateChronotypes/>
                </Tab>
                <Tab eventKey="physicalskills" title="Физические способности">
                    <CreatePhysicalSkills/>
                </Tab>
                <Tab eventKey="origin" title="Происхождение">
                    <CreateOrigin/>
                </Tab>
            </Tabs>

        </section>
    )
}
export default CreateCabinetSection