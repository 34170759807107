import React,{ useState } from 'react'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import {useForm} from "react-hook-form";
import '../create-cabinet-section.sass'
import ModalSave from '../../modal-save'
import { optionLevel} from "./physical-skills-data";

const CreatePhysicalSkills = ({ genobiosisService })=> {
    const [openModal, setOpenModal] = useState(false)
    const { register, handleSubmit, errors } = useForm()

    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: value.user_id,
            need_description: value.need_description,
            need_level:value.need_level,
            intensity_description:value.intensity_description,
            intensity_level:value.intensity_level,
            intensity_value:value.intensity_value,
            parameters:[
                {
                    power:value.power,
                    endurance:value.endurance,
                    motivation:value.motivation
                },
            ]
        }
        let data = JSON.stringify(JSONData)
        // console.log(JSONData)
        genobiosisService.physicalskillsCreate({
            data
        })
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }
    return(
        <div className="create-food-wrapped">
            <ModalSave open={openModal} onClose={()=>setOpenModal(false)} />
            <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                <div className='section-wrapped'>
                    <div>
                        <label htmlFor="user_id">User ID</label>
                        <input  type='text' name="user_id" placeholder="User ID" ref={register({ required: true })}
                            // defaultValue={'17'}
                        />
                    </div>
                </div>
                <div className='section-wrapped'>
                    <h4>Физические способности</h4>
                    <div>
                        <div><b>Потребность в физической активности</b></div>
                        <span>
                                <label htmlFor={'need_level'}>Уровень</label>
                                <select name="need_level" ref={register}>
                                    {optionLevel.map((data, idx)=>{
                                        return <option key={idx} value={data.level}>{data.level}</option>
                                    })}
                                </select>
                            </span>
                        <div>
                            <label htmlFor={'need_description'}>Pекомендации</label>
                            <input type='text' name={'need_description'} placeholder="Рекомендации"
                                   ref={register({required: true})}
                                // defaultValue={'A'}
                            />
                            {errors['need_description'] && <p className="error-text">{'*required'}</p>}
                        </div>
                    </div>
                    <div>
                        <div><b>Оптимальная интенсивность физической активности</b></div>
                        <span>
                            <label htmlFor={'intensity_level'}>Уровень</label>
                            <select name="intensity_level" ref={register}>
                                {optionLevel.map((data, idx)=>{
                                    return <option key={idx} value={data.level}>{data.level}</option>
                                })}
                            </select>
                        </span>
                        <span>
                            <label htmlFor={'intensity_value'}>Значение</label>
                            <input type='text' name={'intensity_value'} placeholder="Значение"
                                   ref={register({required: true})}
                                // defaultValue={'A'}
                            />
                            {errors['intensity_value'] && <p className="error-text">{'*required'}</p>}
                        </span>
                        <div>
                            <label htmlFor={'intensity_description'}>Pекомендации</label>
                            <input type='text' name={'intensity_description'} placeholder="Рекомендации"
                                   ref={register({required: true})}
                                // defaultValue={'A'}
                            />
                            {errors['intensity_description'] && <p className="error-text">{'*required'}</p>}
                        </div>

                    </div>
                    <div>
                    <div><b>Параметри физической активности</b></div>
                    <span>
                        <label htmlFor={'power'}>Сила</label>
                        <select name="power" ref={register}>
                            {optionLevel.map((data, idx)=>{
                                return <option key={idx} value={data.level}>{data.level}</option>
                            })}
                        </select>
                    </span>
                    <span>
                        <label htmlFor={'endurance'}>Выносливость</label>
                        <select name="endurance" ref={register}>
                            {optionLevel.map((data, idx)=>{
                                return <option key={idx} value={data.level}>{data.level}</option>
                            })}
                        </select>
                    </span>
                    <span>
                        <label htmlFor={'motivation'}>Мотивация</label>
                        <select name="motivation" ref={register}>
                            {optionLevel.map((data, idx)=>{
                                return <option key={idx} value={data.level}>{data.level}</option>
                            })}
                        </select>
                    </span>
                    </div>
                </div>
                <button  type="submit">Отправить</button>
            </form>
        </div>
    )
}
export default withGenobiosisService()(CreatePhysicalSkills)