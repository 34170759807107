import React,{ useState } from 'react'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import {useForm} from "react-hook-form";
import '../create-cabinet-section.sass'
import ModalSave from '../../modal-save'

const CreateChronotypes= ({ genobiosisService })=> {
    const [openModal, setOpenModal] = useState(false)
    const {register, handleSubmit, errors} = useForm()

    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: value.user_id,
            time: value.time,
            result:value.result
        }
        let data = JSON.stringify(JSONData)
        // console.log(JSONData)
        genobiosisService.chronotypeCreate({
            data
        })
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }
    return (
        <div className="create-food-wrapped">
            <ModalSave open={openModal} onClose={() => setOpenModal(false)}/>
            <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                <div className='section-wrapped'>
                    <div>
                        <label htmlFor="user_id">User ID</label>
                        <input type='text' name="user_id" placeholder="User ID" ref={register({required: true})}
                            // defaultValue={'17'}
                        />
                    </div>
                </div>
                <div className='section-wrapped'>
                    <h4>Хронотип</h4>
                    <span>
                        <label htmlFor={'time'}>Время</label>
                        <input type='time' name={'time'} placeholder="Время"
                               ref={register({required: true})}
                               // defaultValue={'8:00'}
                        />
                        {errors['time'] && <p className="error-text">{'*required'}</p>}
                    </span>
                    <div>
                        <label htmlFor={'result'}>Результат</label>
                        <input type='text' name={'result'} placeholder="Результат"
                               ref={register({required: true})}
                               // defaultValue={'A'}
                        />
                        {errors['result'] && <p className="error-text">{'*required'}</p>}
                    </div>
                </div>
                <button type="submit">Отправить</button>
            </form>
        </div>
    )
}
export default withGenobiosisService()(CreateChronotypes)