import React,{ useState } from 'react'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import {useForm} from "react-hook-form";
import '../create-cabinet-section.sass'
import ModalSave from '../../modal-save'
import {illnessData} from "./illness-data";

const CreateIllness = ({ genobiosisService })=>{
    const [openModal, setOpenModal] = useState(false)
    const { register, handleSubmit, errors } = useForm()
    const yearChance = [
        {yearChance: '1'},
        {yearChance: '10'},
        {yearChance: '20'},
        {yearChance: '30'},
        {yearChance: '40'},
        {yearChance: '50'},
        {yearChance: '60'},
        {yearChance: '70'},
        {yearChance: '80'},
        {yearChance: '90'},
        {yearChance: '100'},
    ]



    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: value.user_id,
            illnessItems:[

            ]
        }
        const fields = (idx)=>{
            return{
                name: illnessData[idx].name,
                chance: value[`chance${idx}`],
                recommendations: value[`recommendations${idx}`],
                illness_probability: [
                    {
                        middle_chance: value[`middle_chance${idx}`],
                        illness_chance: [
                            value[`${idx}illness_chance${0}`],
                            value[`${idx}illness_chance${1}`],
                            value[`${idx}illness_chance${2}`],
                            value[`${idx}illness_chance${3}`],
                            value[`${idx}illness_chance${4}`],
                            value[`${idx}illness_chance${5}`],
                            value[`${idx}illness_chance${6}`],
                            value[`${idx}illness_chance${7}`],
                            value[`${idx}illness_chance${8}`],
                            value[`${idx}illness_chance${9}`],
                            value[`${idx}illness_chance${10}`],
                        ],
                        illness_chance_middle: illnessData[idx].illness_chance_middle,
                        link: illnessData[idx].link,
                        chart_percent: illnessData[idx].chart_percent

                    }
                ]
            }
        }
        for(let i = 0; i<=illnessData.length; i++){
            if (value[`chance${i}`]) {
                JSONData.illnessItems.push(fields(i))
            }

        }

        let data = JSON.stringify(JSONData)
        console.log(JSONData)
        genobiosisService.illnessCreate({
            data
        })
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }
    return(
        <div className="create-food-wrapped">
            <ModalSave open={openModal} onClose={()=>setOpenModal(false)} />
            <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                <div className='section-wrapped'>
                    <div>
                        <label htmlFor="user_id">User ID</label>
                        <input  type='text' name="user_id" placeholder="User ID" ref={register({ required: true })}
                                // defaultValue={'17'}
                        />
                    </div>
                </div>
                <div className='section-wrapped'>
                    <h4>Риски болезней</h4>
                    {illnessData.map((data, idx)=>{
                        return(
                            <div key={idx}>
                                <div className="article-name"><b>{data.name}</b></div>
                                <div>
                                    <label htmlFor={'chance'+idx}>Ваш Риск</label>
                                    <input type='number'  step="any" name={'chance'+idx} placeholder="Риск"
                                           ref={register({required: true})}
                                           defaultValue={'11'+idx}
                                    />
                                </div>
                                {errors['chance'+idx] && <p className="error-text">{'*required'}</p>}
                                <div>
                                    <label htmlFor={'middle_chance'+idx}>Средний Риск</label>
                                    <input type='number' step="any" name={'middle_chance'+idx} placeholder="Средний Риск"
                                           ref={register({required: true})}
                                           defaultValue={'22'+idx}
                                    />
                                </div>
                                {errors['middle_chance'+idx] && <p className="error-text">{'*required'}</p>}
                                <div>
                                    <label htmlFor={'recommendations'+idx}>Рекомендации</label>
                                    <input type='text' name={'recommendations'+idx} placeholder="Рекомендации"
                                           ref={register({required: true})}
                                           defaultValue={'33'+idx}
                                    />
                                </div>
                                {errors['recommendations'+idx] && <p className="error-text">{'*required'}</p>}
                                <div>
                                    <div><b>Ваш риск (диаграмма)</b></div>
                                    {yearChance.map((val, index) => {
                                        return (
                                            <span key={index}>
                                                <label htmlFor={idx+'illness_chance'+index}>{val.yearChance}</label>
                                                 <input type='number' step="any" name={idx+'illness_chance'+index} placeholder="%" ref={register({ required: true })}
                                                        defaultValue={idx+'112'+index}
                                                 />
                                                {errors[idx+'illness_chance'+index] && <p className="error-text">{'*required'}</p>}
                                            </span>
                                        )
                                    })
                                    }

                                </div>
                            </div>
                            )
                        })

                    }

                </div>
                <button  type="submit">Отправить</button>
            </form>
        </div>
    )
}
export default withGenobiosisService()(CreateIllness)