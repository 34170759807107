import React from 'react';
import  ReactDOM  from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/app';
import ErrorBoundry from './components/error-boundry';
import GenobiosisService from './services/genobiosis-service';
import { GenobiosisServiceProvider } from './components/genobiosis-service-context';
import store from './store';
const genobiosisService = new GenobiosisService();


ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundry>
            <Router>
                <GenobiosisServiceProvider value={genobiosisService}>
                    <App/>
                </GenobiosisServiceProvider>
            </Router>
        </ErrorBoundry>
    </Provider>,
    document.getElementById('root'))





