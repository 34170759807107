import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import {useForm} from "react-hook-form";
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import ModalSave from '../../modal-save'
import '../create-cabinet-section.sass'

const UpdateOrigin = ({customerId, genobiosisService})=> {
    const [open, setOpen] = useState(false)
    const [getData, setGetData] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [origin, setOrigin] = useState('')
    const {register, handleSubmit} = useForm();

    const testFunc = ()=> {
        genobiosisService.originGet(customerId)
            .then((data)=>{
                const mainData = data.data.data
                setGetData(mainData)
                console.log(mainData)
                setOrigin(
                    <div>
                        <div><b>Ваш предок</b></div>
                        <div>
                            <label htmlFor={'ancestor_description'}>Ваш результат</label>
                            <input type='text' name={'ancestor_description'} placeholder="Ваш результат"
                                   ref={register}
                                   defaultValue={mainData.ancestor_description}
                            />
                        </div>
                        <div>
                            <label htmlFor={'ancestor'}>Предок</label>
                            <select name="ancestor" ref={register} >
                                <option value="Неандерталец" selected={mainData.ancestor} >Неандерталец</option>
                            </select>
                        </div>
                        <div><b>Этнический состав</b></div>
                        {
                            mainData.ethnicComposition.map((data, idx)=>{
                                return(
                                    <div key={idx}>
                                        <span>
                                            <label htmlFor={'ethnic'+idx}>Этнический состав</label>
                                            <input type='text' name={'ethnic'+idx} placeholder="Этнический состав"
                                                   ref={register}
                                                   defaultValue={data.value}
                                            />
                                        </span>
                                        <span>
                                            <label htmlFor={'percent'+idx}>%</label>
                                            <input type='number'  step="any" name={'percent'+idx} placeholder="%"
                                                   ref={register}
                                                   defaultValue={data.percent}
                                            />
                                        </span>
                                    </div>
                                )
                            })
                        }
                        <div>
                            <div><b>Материнская гаплогруппа</b></div>
                            <div>
                                <label htmlFor={'maternalHaplogroup_result'}>Результат</label>
                                <input type='text' name={'maternalHaplogroup_result'} placeholder="Результат"
                                       ref={register} defaultValue={mainData.maternalHaplogroup.result}
                                />
                            </div>
                            <div>
                                <label htmlFor={'maternalHaplogroup_description'}>Описание</label>
                                <input type='text' name={'maternalHaplogroup_description'} placeholder="Описание"
                                       ref={register} defaultValue={mainData.maternalHaplogroup.origin_description}
                                />
                            </div>
                            <div>
                                <label htmlFor={'maternalHaplogroup_load_map'}>Загрузить карту</label>
                                <input type="file" name={'maternalHaplogroup_load_map'} accept="image/jpg, image/jpeg, image/png" ref={register} />
                            </div>
                            {mainData.maternalHaplogroup.ancestors.map((field, idx) => {
                                    return (
                                        <div key={idx}>
                                            <span>
                                                <label htmlFor={'0common_ancestor'+idx}>Общий предок</label>
                                                <input type='text' name={'0common_ancestor'+idx} placeholder="Общий предок"
                                                       ref={register}
                                                       defaultValue={field.common_ancestor}
                                                />
                                            </span>
                                            <span>
                                                <label htmlFor={'0royal_ancestor'+idx}>Королевский предок</label>
                                                <input type='text' name={'0royal_ancestor'+idx} placeholder="Королевский предок"
                                                       ref={register}
                                                       defaultValue={field.royal_ancestor}
                                                />
                                            </span>
                                            <span>
                                                <label htmlFor={'0result_ancestor'+idx}>Результат</label>
                                                <input type='text' name={'0result_ancestor'+idx} placeholder="Результат"
                                                       ref={register}
                                                       defaultValue={field.result}
                                                />
                                            </span>

                                        </div>
                                    )

                                })
                            }
                        </div>
                        <div>
                            <div><b>Отцовская гаплогруппа</b></div>
                            <div>
                                <label htmlFor={'fatherHaplogroup_result'}>Результат</label>
                                <input type='text' name={'fatherHaplogroup_result'} placeholder="Результат"
                                       ref={register} defaultValue={mainData.fatherHaplogroup.result}
                                />
                            </div>
                            <div>
                                <label htmlFor={'fatherHaplogroup_description'}>Описание</label>
                                <input type='text' name={'fatherHaplogroup_description'} placeholder="Описание"
                                       ref={register} defaultValue={mainData.fatherHaplogroup.origin_description}
                                />
                            </div>
                            <div>
                                <label htmlFor={'fatherHaplogroup_load_map'}>Загрузить карту</label>
                                <input type="file" name={'fatherHaplogroup_load_map'} accept="image/jpg, image/jpeg, image/png" ref={register} />
                            </div>
                            {mainData.fatherHaplogroup.ancestors.map((field, idx) => {
                                return (
                                    <div key={idx}>
                                        <span>
                                            <label htmlFor={'1common_ancestor'+idx}>Общий предок</label>
                                            <input type='text' name={'1common_ancestor'+idx} placeholder="Общий предок"
                                                   ref={register}
                                                   defaultValue={field.common_ancestor}
                                            />
                                        </span>
                                        <span>
                                            <label htmlFor={'1royal_ancestor'+idx}>Королевский предок</label>
                                            <input type='text' name={'1royal_ancestor'+idx} placeholder="Королевский предок"
                                                   ref={register}
                                                   defaultValue={field.royal_ancestor}
                                            />
                                        </span>
                                        <span>
                                            <label htmlFor={'1result_ancestor'+idx}>Результат</label>
                                            <input type='text' name={'1result_ancestor'+idx} placeholder="Результат"
                                                   ref={register}
                                                   defaultValue={field.result}
                                            />
                                        </span>
                                    </div>
                                )

                            })
                            }
                        </div>
                    </div>
                )
                setOpen(true)
            })
            .catch(err=>console.log(err))
    }
    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: customerId + '',
            ancestor: value.ancestor,
            ancestor_description: value.ancestor_description,
            ethnicComposition: [],
            maternalHaplogroup: {
                result: value.maternalHaplogroup_result,
                origin_description: value.maternalHaplogroup_description,
                // origin_map: getData.maternalHaplogroup.origin_map,
                ancestors:[]
            },
            fatherHaplogroup: {
                    result: value.fatherHaplogroup_result,
                    origin_description: value.fatherHaplogroup_description,
                    // origin_map: getData.fatherHaplogroup.origin_map,
                    ancestors:[]
            }
            // result:value.result
        }

        const fieldsItems = (idx)=>{
            return{
                value: value['ethnic'+idx],
                percent: value['percent'+idx],
            }
        }
        for(let i = 0; i<getData.ethnicComposition.length; i++){
            JSONData.ethnicComposition.push(fieldsItems(i))
        }
        const motherHaplogroup = (idx)=> {
            return {
                common_ancestor: value['0common_ancestor' + idx],
                royal_ancestor: value['0royal_ancestor' + idx],
                result: value['0result_ancestor' + idx],
            }
        }

        for(let j = 0; j<getData.maternalHaplogroup.ancestors.length; j++){
            JSONData.maternalHaplogroup.ancestors.push(motherHaplogroup(j))
        }

        const fatherHaplogroup = (idx)=> {
            return {
                common_ancestor: value['1common_ancestor' + idx],
                royal_ancestor: value['1royal_ancestor' + idx],
                result: value['1result_ancestor' + idx],
            }
        }
        for(let i = 0; i<getData.fatherHaplogroup.ancestors.length; i++){
            JSONData.fatherHaplogroup.ancestors.push(fatherHaplogroup(i))
        }
        // console.log(getData.fatherHaplogroup.origin_map)
        // console.log(getData.maternalHaplogroup.origin_map)
        if(value.maternalHaplogroup_load_map[0]!==undefined&&value.fatherHaplogroup_load_map[0]===undefined){
            const formData = new FormData()
            JSONData.maternalHaplogroup.origin_map=null
            JSONData.fatherHaplogroup.origin_map=getData.fatherHaplogroup.origin_map
            let data = JSON.stringify(JSONData)
            formData.append('data', data)
            formData.append('motherImage', value.maternalHaplogroup_load_map[0])
            genobiosisService.originUpdate(customerId, formData)
                .then(() => {
                        setOpenModal(true)
                        event.target.reset()
                    }
                )
                .catch(err => console.log(err))
        }
        if(value.maternalHaplogroup_load_map[0]===undefined&&value.fatherHaplogroup_load_map[0]!==undefined){
            const formData = new FormData()
            JSONData.maternalHaplogroup.origin_map=getData.maternalHaplogroup.origin_map
            JSONData.fatherHaplogroup.origin_map=null
            let data = JSON.stringify(JSONData)
            formData.append('data', data)
            formData.append('fatherImage', value.fatherHaplogroup_load_map[0])
            genobiosisService.originUpdate(customerId, formData)
                .then(() => {
                        setOpenModal(true)
                        event.target.reset()
                    }
                )
                .catch(err => console.log(err))
        }
        if(value.maternalHaplogroup_load_map[0]!==undefined&&value.fatherHaplogroup_load_map[0]!==undefined){
            const formData = new FormData()
            JSONData.maternalHaplogroup.origin_map=null
            JSONData.fatherHaplogroup.origin_map=null
            let data = JSON.stringify(JSONData)
            formData.append('data', data)
            formData.append('fatherImage', value.fatherHaplogroup_load_map[0])
            formData.append('motherImage', value.maternalHaplogroup_load_map[0])
            genobiosisService.originUpdate(customerId, formData)
                .then(() => {
                        setOpenModal(true)
                        event.target.reset()
                    }
                )
                .catch(err => console.log(err))
        }

        if(value.fatherHaplogroup_load_map[0]===undefined&&value.maternalHaplogroup_load_map[0]===undefined){
                JSONData.maternalHaplogroup.origin_map=getData.maternalHaplogroup.origin_map
                JSONData.fatherHaplogroup.origin_map=getData.fatherHaplogroup.origin_map
                let data = JSON.stringify(JSONData)
                genobiosisService.originUpdate(customerId, {data})
                .then(() => {
                        setOpenModal(true)
                        event.target.reset()
                    }
                )
                .catch(err => console.log(err))
        }
    }
    const closeConfirm = ()=>{
        setOpenModal(false)
        setOpen(false)
    }
    return (
        <div>
            <button onClick={testFunc}>Update Origin</button>
            <ModalSave open={openModal} onClose={closeConfirm} />
            <Modal show={open} onHide={()=>{setOpen(false)}} dialogClassName="modal-90w">
                <Modal.Header closeButton/>
                <div className="create-food-wrapped">
                    <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                        <div className='section-wrapped'>
                            <h4>Происхождение</h4>
                            {origin}
                        </div>
                        <button  type="submit">Изменить</button>
                    </form>
                </div>
            </Modal>
        </div>

    )
}
export default withGenobiosisService()(UpdateOrigin)