import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import {useForm} from "react-hook-form";
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import ModalSave from '../../modal-save'
import '../create-cabinet-section.sass'

const UpdateChronotypes = ({customerId, genobiosisService})=> {
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [chronotype, setChronotype] = useState('')
    const {register, handleSubmit} = useForm();

    const testFunc = ()=> {
        genobiosisService.chronotypeGet(customerId)
            .then((data)=>{
                const mainData = data.data.data
                setChronotype(
                    <div>
                        <span>
                            <label htmlFor={'time'}>Время</label>
                            <input type='time' name={'time'} placeholder="Время"
                                   ref={register} defaultValue={mainData.time}/>
                        </span>
                        <div>
                            <label htmlFor={'result'}>Результат</label>
                            <input type='text' name={'result'} placeholder="Результат"
                                           ref={register} defaultValue={mainData.result}/>
                        </div>
                    </div>
                )
                setOpen(true)
             })
            .catch(err=>console.log(err))
    }
    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            user_id: customerId + '',
            time: value.time,
            result:value.result
        }
        let data = JSON.stringify(JSONData)
        // console.log(JSONData)
        genobiosisService.chronotypeUpdate(customerId,{data})
            .then(() => {
                    setOpenModal(true)
                    event.target.reset()
                }
            )
            .catch(err => console.log(err))
    }
    const closeConfirm = ()=>{
        setOpenModal(false)
        setOpen(false)
    }
    return (
        <div>
            <button onClick={testFunc}>Update Chronotypes</button>
            <ModalSave open={openModal} onClose={closeConfirm} />
            <Modal show={open} onHide={()=>{setOpen(false)}} dialogClassName="modal-90w">
                <Modal.Header closeButton/>
                <div className="create-food-wrapped">
                    <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                        <div className='section-wrapped'>
                            <h4>Хронотип</h4>
                            {chronotype}
                        </div>
                        <button  type="submit">Изменить</button>
                    </form>
                </div>
            </Modal>
        </div>

    )
}
export default withGenobiosisService()(UpdateChronotypes)