import React, {useState} from 'react'
import withGenobiosisService from "../hoc/with-genobiosis-service";
import ModalConfirmDelete from "./modal-confirm-delete";

const DeleteBtn =({ genobiosisService, customerId})=> {
    const [openModal, setOpenModal] = useState(false)
    const deleteCustomer = ()=>{
        genobiosisService.deleteConsumer(customerId)
            .catch((err) => console.log(err));
        setOpenModal(false)
    }

        return(
            <div>
                <ModalConfirmDelete open={openModal} onClose={()=>setOpenModal(false)} onConfirm={deleteCustomer} />
                <button onClick={() => setOpenModal(true)}>Delete</button>
            </div>

        )

}

export default withGenobiosisService()(DeleteBtn)