import React from 'react'
import { Modal } from 'react-bootstrap';
import './modal-confirm/modal-confirm.sass'


const ModalSave =({open, onClose})=>{
    return (
        <Modal show={open} onHide={onClose} centered size="xl">
            <div className="wrapped-modal-confirm d-flex">
                <Modal.Header closeButton/>
                <p>Данные сохранены успешно</p>
                <p>Будут доступны после перезагрузки</p>
                <button onClick={onClose}>Ok</button>
            </div>
        </Modal>
    )
}
export default ModalSave