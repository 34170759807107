export const psychogeneticData = [
    {
        gen: "DRD2",
        product_gen:"D2-рецептор дофамина"
    },
    {
        gen: "HTR2A",
        product_gen:"5-HT2A-рецептор серотонина"
    },
    {
        gen: "CYP2A6",
        product_gen:"Цитохром P450 2A6 – фермент, метаболизирующий никотин до котинина"
    },

    {
        gen: "COMT",
        product_gen:"Катехол-О- метилтрансфераза"
    },

    {
        gen: "BDNF",
        product_gen:"Нейротрофический фактор мозга"
    },

    {
        gen: "CHRNA5",
        product_gen:"α5-субъединица нейронального ацетилхолинового рецептора"
    },

    {
        gen: "DBH",
        product_gen:"Дофамин-β-гидроксилаза"
    },

    {
        gen: "SLC6A4",
        product_gen:"Транспортер серотонина (обеспечивает обратный захват)"
    },

    {
        gen: "CYP2A6",
        product_gen:"Цитохром P450 2A6 – фермент, метаболизирующий никотин до котинина"
    },

    {
        gen: "GRIN2A",
        product_gen:"2А-субъединица глутаматного ионотропного рецептора NMDA-типа"
    },

    {
        gen: "CACNA1C",
        product_gen:"Кальций-зависимый потенциал-управляемый канал CaV1.2, субъединица альфа-1 C"
    },

    {
        gen: "CACNB2",
        product_gen:"Вспомогательная субъединица Beta 2 Кальций-зависимого потенциал-управляемого канала L-типа"
    },
    {
        gen: "PDE10A",
        product_gen:"Фосфодиэстераза 10А"
    },
    {
        gen: "OLFM4",
        product_gen:"Ольфактомедин 4"
    },
    {
        gen: "SLC6A15",
        product_gen:"Натрий / хлор-зависимый транспортер нейротрансмиттеров"
    }

]
